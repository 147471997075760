const placesFromProps = (props) => {
  if(props.places){
    const  transformedPlaces = props.places.map(p => {
      const { latitude, longitude, streetAddress, postCode } = p.mi_participant.miParticipantGeodata;
      const shortTitle = p.mi_participant.miParticipantShorttitle
      const heroImage =p.mi_participant.miParticipantHeroSlider

      const category = p.participantCategories.nodes[0];
      // console.log('category: ', category)
      return {
        title: p.title,
        shortTitle,
        heroImage,
        slug: p.slug,
        uri: p.uri,
        formatted_address : streetAddress,
        id: p.databaseId,
        lat : latitude,
        lng : longitude,
        show: false,
        zip: postCode,
        categoryId : category.databaseId,
        category: {
          id: category.databaseId,
          name: category.name
        }
      }
    })
    return transformedPlaces;
  }
  return []
}


export { placesFromProps as default }