const defaults = {
  active_category : {
    id: null,
    name: ''
  }
}

function map(state = defaults, action ) {
  switch(action.type){
  case 'FILTER_PARTICIPANTS':
    // console.log('FILTER_PARTICIPANTS ACTION: ', action)
    return { 
      ...state,
      active_category: { 
        id: action.participant_category_id, 
        name: action.participant_category_name,
        slug: action.participant_category_slug
      }
    }

  case 'SET_ACTIVE_PARTICIPANT':
    return { 
      ...state,
      active_participant_id: action.active_participant_id, 
    }

  case 'SET_SINGLE_PARTICIPANT_DATA':
    return { 
      ...state,
      participantData: action.participant_data, 
    }

  case 'SET_CURRENT_PARTICIPANTS':
    return { 
      ...state,
      participants: action.participants, 
    }
  

  case 'ACTIVATE_PARTICIPANT':
    return { 
      ...state,
      activated_participant_id: action.activated_participant_id, 
    }

  case 'OPEN_PARTICIPANT':
    return { 
      ...state,
      opened_participant_id: action.opened_participant_id, 
    }

  case 'SET_ACTIVATED_PARTICIPANT_OFFSET_X':
    return { 
      ...state,
      activated_participant_offset_x: action.activated_participant_offset_x, 
    }

  case 'INITIALIZE_PARTICIPANT_CATEGORY':
    // console.log('INITIALIZE_PARTICIPANT_CATEGORY ACTION: ', action)
    return { 
      ...state,
      active_category: { 
        id: action.participant_category_id, 
        name: action.participant_category_name  
      }
    }
  default:
    // console.log('REDUCER default map_count')
    return state;
  }
}

export default map;
