module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Moabiter Insel","short_name":"Moabiter Insel","description":"Entdecke deine Nachbarschaft in Moabit","lang":"de","start_url":"/","background_color":"#f2ebe7","theme_color":"#ea5223","display":"standalone","icon":"src/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"15b12b32a7520639632919d352567b51"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
