import React from 'react';
import { connect } from "react-redux";
import { optInMaps } from '../../state/actions'

const OptInDialog = (props) => {

  console.log('OptInDialog, props: ', props)
  const { map_opt_in_confirmed, map_opt_in_count, optInMaps } = props;

  console.log(optInMaps);
  console.log('maps_allowed ', map_opt_in_confirmed ? 'true' : 'false');
  
  return (
    <div>
      {/* <pre>maps_allowed: {maps_allowed ? 'true' : 'false'}</pre> */}
      <span>
        map_count:
        {map_opt_in_count}
      </span>
      <button type="submit" onClick={optInMaps}>Google zulassen</button>
    </div>
  )}

const mapStateToProps = state => {
  console.log('mapStateToProps OptInDialog: ', state)
  return {
    map_opt_in_count: state.map.opt_in.count,
    map_opt_in_confirmed: state.map.opt_in.confirmed,
    map_opt_in_persisted: state.map.opt_in.persisted,
  }
};

const mapDispatchToProps = {
  optInMaps,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OptInDialog);
