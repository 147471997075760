import React from "react";
import styled from '@emotion/styled'

export default function ArtsAndCrafts(props) {
  const { active } = props;
  
  return (
    <StyledSVG 
      active={active}
      xmlns="http://www.w3.org/2000/svg"
      width="60" 
      height="60" 
      viewBox="0 0 60 60"
    >
      <g xmlns="http://www.w3.org/2000/svg" fill="none" fillRule="evenodd" transform="translate(2 2)">
        <polygon fill="#FEC110" points="28 49.239 21.769 55.298 18.785 47.136 10.542 49.891 11.395 41.242 2.773 40.149 7.294 32.726 0 28 7.294 23.274 2.773 15.851 11.395 14.758 10.542 6.109 18.785 8.864 21.769 .702 28 6.761 34.231 .702 37.215 8.864 45.458 6.109 44.605 14.758 53.227 15.851 48.706 23.274 56 28 48.706 32.726 53.227 40.149 44.605 41.242 45.458 49.891 37.215 47.136 34.231 55.298" className={active? 'mi-bg-active' : 'mi-bg-inactive'} />
        <polygon fill="#FFFFFF" fillRule="nonzero" points="37 23 19 23 19.225 40 36.775 40" className={active? 'mi-ba-active' : 'mi-ba-inactive'} />
        <path fill="#000000" fillRule="nonzero" d="M28,15 C31.2383969,15 33.8775718,17.5655749 33.9958615,20.7750617 L34,21 L34,26 C34,26.5522847 33.5522847,27 33,27 C32.4871642,27 32.0644928,26.6139598 32.0067277,26.1166211 L32,26 L32,21 C32,18.790861 30.209139,17 28,17 C25.8578046,17 24.1089211,18.6839685 24.0048953,20.8003597 L24,21 L24,26 C24,26.5522847 23.5522847,27 23,27 C22.4871642,27 22.0644928,26.6139598 22.0067277,26.1166211 L22,26 L22,21 C22,17.6862915 24.6862915,15 28,15 Z" className={active? 'mi-hd-active' : 'mi-hd-inactive'} />
      </g>
    </StyledSVG>
  )
}

const StyledSVG = styled.svg`
  width: 100%;
  height: 100%;
  .mi-bg-active{
    fill: #FEC110;
  }
  .mi-bg-inactive{
    fill: #ffffff;
  }
  .mi-ba-active{
    fill: #ffff;
  }
  .mi-ba-inactive{
    fill: #E9D8CF;
  }
  .mi-hd-active{
    fill: #000000;
  }
  .mi-hd-inactive{
    fill: #AE9C93;
  }

`
/*
  The following does not work
  It seams like react does re-render the whole filterbar, 
  when the action "filterParticipants" is called.
  Trying to find a solution for an animated transition of the icons
  is to time-consuming for now


  Idea:

  Do not connect the Filterbar component to redux,
  but connect every single FilterItem, so not the whole
  component is re-rendered on a prop change
*/

/*
const StyledDiv = styled.div`
  width: 100%;
  height: 100%;

  transform: ${({ active }) => active ? 'translateX(0)' : 'translateX(50%)'};
  transition-timing-function: ${({ active }) => active ? 'cubic-bezier(0.0, 0.0, 0.2, 1)' : 'cubic-bezier(0.2, 0, 1, 0.9)'};
  transition-duration: ${({ active }) => active ? '1250ms' : '1200ms'};
  transition-property: transform;
`
*/

