import React from "react";
import styled from '@emotion/styled'

export default function ArtsAndCrafts(props) {
  const { active } = props;
  
  return (
    <StyledSVG 
      active={active}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <g xmlns="http://www.w3.org/2000/svg" fill="none" fillRule="evenodd">
        <path fill="#000000" fillRule="nonzero" d="M7.01199244 10.5992106L7.4920279 10.9462211C11.6270275 13.9732634 12.9895703 16.0150135 13.0386168 19.2552932L13.04 19.44 13.04 24.56C13.04 24.9134622 12.7534622 25.2 12.4 25.2 12.0717851 25.2 11.8012754 24.9529343 11.7643057 24.6346375L11.76 24.56 11.76 19.44C11.76 16.454119 10.5570738 14.7044082 6.26800756 11.6407894 5.9803835 11.4353437 5.91376482 11.0356316 6.11921058 10.7480076 6.32465633 10.4603835 6.72436839 10.3937648 7.01199244 10.5992106zM25.4679578 10.5992106C25.7555819 10.3937648 26.155294 10.4603835 26.3607397 10.7480076 26.5661855 11.0356316 26.4995668 11.4353437 26.2119427 11.6407894 22.0023037 14.6476745 20.7655615 16.3888643 20.7211998 19.2753733L20.7199503 19.44 20.7199503 24.56 20.7156445 24.6346375C20.6786749 24.9529343 20.4081652 25.2 20.0799503 25.2 19.7517354 25.2 19.4812257 24.9529343 19.444256 24.6346375L19.4399503 24.56 19.4399503 19.44 19.4413335 19.2552932C19.4894368 16.0773266 20.801003 14.0522355 24.7524303 11.1197805L24.9879224 10.9462211 25.4679578 10.5992106z" className={active? 'mi-ft-active' : 'mi-ft-inactive'}  />
        <path fill="#DDF2E7" fillRule="nonzero" d="M16.24,6 C18.7142357,6 20.72,8.00576432 20.72,10.48 C20.72,12.9542357 18.7142357,14.96 16.24,14.96 C13.7657643,14.96 11.76,12.9542357 11.76,10.48 C11.76,8.00576432 13.7657643,6 16.24,6 Z" className={active? 'mi-hd-active' : 'mi-hd-inactive'} />
      </g>
    </StyledSVG>
  )
}

const StyledSVG = styled.svg`
  width: 100%;
  height: 100%;
  .mi-ft-active{
    fill: #000000;
  }
  .mi-ft-inactive{
    fill: #57C18B;
  }
  .mi-hd-active {
    fill: #DDF2E7;
  }
  .mi-hd-inactive{
    fill: #57C18B;
  }
`
