import React from "react";
import styled from '@emotion/styled'

export default function ArtsAndCrafts(props) {
  const { active } = props;
  
  return (
    <StyledSVG 
      active={active}
      xmlns="http://www.w3.org/2000/svg"
      width="60" 
      height="60" 
      viewBox="0 0 60 60"
    >
      <g xmlns="http://www.w3.org/2000/svg" fill="none" fillRule="evenodd" transform="translate(5 5)">
        <circle cx="25" cy="25" r="25" fill="#EA5223" className={active? 'mi-bg-active' : 'mi-bg-inactive'} />
        <path fill="#FFFFFF" fillRule="nonzero" d="M34,33 L34,13.9814325 L32.7566666,14.0043077 C28.9831404,14.1432156 26,17.231652 26,21 L26,33 L34,33 Z" className={active? 'mi-kn-active' : 'mi-kn-inactive'} />
        <path fill="#000000" fillRule="nonzero" d="M33,13 C33.5128358,13 33.9355072,13.3860402 33.9932723,13.8833789 L34,14 L34,40 C34,40.5522847 33.5522847,41 33,41 C32.4871642,41 32.0644928,40.6139598 32.0067277,40.1166211 L32,40 L32,14 C32,13.4477153 32.4477153,13 33,13 Z M22,14 C22.5128358,14 22.9355072,14.3860402 22.9932723,14.8833789 L23,15 L23,21 C23,23.4185387 21.2828356,25.4360122 19.0011864,25.8997409 L19,39 C19,39.5522847 18.5522847,40 18,40 C17.4871642,40 17.0644928,39.6139598 17.0067277,39.1166211 L17,39 L17.0000852,25.8999992 C14.7863647,25.4506779 13.1037188,23.5388174 13.0046195,21.2168896 L13,21 L13,15 C13,14.4477153 13.4477153,14 14,14 C14.5128358,14 14.9355072,14.3860402 14.9932723,14.8833789 L15,15 L15,21 C15,22.3062521 15.8348501,23.4175144 17.0000889,23.8293257 L17,15 C17,14.4477153 17.4477153,14 18,14 C18.5128358,14 18.9355072,14.3860402 18.9932723,14.8833789 L19,15 L19.0009007,23.8289758 C20.1128456,23.4355582 20.9237055,22.4051328 20.9949073,21.1762728 L21,21 L21,15 C21,14.4477153 21.4477153,14 22,14 Z" className={active? 'mi-fk-active' : 'mi-fk-inactive'} />
      </g>
    </StyledSVG>
  )
}

const StyledSVG = styled.svg`
  width: 100%;
  height: 100%;
  .mi-bg-active{
    fill: #EA5223;
  }
  .mi-bg-inactive{
    fill: #ffffff;
  }
  .mi-kn-active{
    fill: #ffffff;
  }
  .mi-kn-inactive{
    fill: #E9D8CF;
  }
  .mi-fk-active{
    fill: #000000;
  }
  .mi-fk-inactive{
    fill: #B5A49A;
  }
`
/*
  The following does not work
  It seams like react does re-render the whole filterbar, 
  when the action "filterParticipants" is called.
  Trying to find a solution for an animated transition of the icons
  is to time-consuming for now


  Idea:

  Do not connect the Filterbar component to redux,
  but connect every single FilterItem, so not the whole
  component is re-rendered on a prop change
*/

/*
const StyledDiv = styled.div`
  width: 100%;
  height: 100%;

  transform: ${({ active }) => active ? 'translateX(0)' : 'translateX(50%)'};
  transition-timing-function: ${({ active }) => active ? 'cubic-bezier(0.0, 0.0, 0.2, 1)' : 'cubic-bezier(0.2, 0, 1, 0.9)'};
  transition-duration: ${({ active }) => active ? '1250ms' : '1200ms'};
  transition-property: transform;
`
*/

