import React from 'react'
import styled from '@emotion/styled'
import { Link as GatsbyLink  } from 'gatsby'
import { connect } from 'react-redux';
import { setIsMapView, setIsCategoryView } from '../../../state/actions'

const placesFromProps = (props) => {

  if(props.places){
    // console.log('props places: ', props.places);

    const  transformedPlaces = props.places.map(p => {
      const { latitude, longitude, streetAddress, postCode } = p.mi_participant.miParticipantGeodata;
      const category = p.participantCategories.nodes[0];
      // console.log('category: ', category)
      return {
        title: p.title,
        slug: p.slug,
        uri: p.uri,
        formatted_address : streetAddress,
        id: p.databaseId,
        lat : latitude,
        lng : longitude,
        show: false,
        zip: postCode,
        category: {
          id: category.databaseId,
          name: category.name
        }
      }
    })
    return transformedPlaces;
  }
  return []
}

const Card = ( props ) => {
  const { 
    setIsMapView,
    setIsCategoryView
  } = props;
  const { place } = props;
  const { slug, uri, title, zip, category } = place;

  return (
    <GatsbyLink 
      className='mi_single_participant'
      key={slug}
      to={uri}
      title={title}
      onClick={() => {
        setIsCategoryView(false)
        setIsMapView(true)}}
    >
      <h4>{title}</h4>
      <h5>{slug}</h5>
      <p>
        PLZ:
        {zip}
      </p>
      <p>
        Category:
        {category.id} 
        <br />
        {category.name}
      </p>
    </GatsbyLink>
  )
}

const ParticipantCardsMobile = ( props ) => {
  const places = placesFromProps(props)
  // const { 
  //   setIsMapView,
  //   setIsCategoryView
  // } = props;

  // console.log('placesFromProps: ', places)
  return (
    <StyledMobileCardContainer>
      {places.map((place) => (
        <Card key={place.id} place={place} {...props} />
      ))}
    </StyledMobileCardContainer>
  );
}

const mapStateToProps = state => {
  // console.log('mapStateToProps footer: ', state)
  return {
    isMapView: state.map.isMapView,
    isCategoryView: state.view.isCategoryView
  }
};

const mapDispatchToProps = {
  setIsMapView,
  setIsCategoryView
};

const StyledMobileCardContainer = styled.div`
  background-color: ${({ theme }) => theme.mi_background_real};
  display: none !important;
  @media(min-width: 768px){
    display: block !important;
  }
`

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantCardsMobile)
