/* eslint-disable camelcase */
function maps_api_key(state = '', action, stateBefore ){
  switch(action.type){
  case '@@INIT':
    return state;
  default:
    // console.log('REDUCER default maps_api_key')
    return state;
  }
}

export default maps_api_key;
