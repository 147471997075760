/* eslint-disable camelcase */
import React from 'react';
// import styled from 'styled-components';
import styled from '@emotion/styled'
import MoabitMap from './MoabitMap';
import OptIn from './OptInDialog';
// import MarkerInfoWindowGmapsObj from '../../examples/MarkerInfoWindowGmapsObj';
// import { css } from '@emotion/core';

export default {
  title: 'MarkerInfo Examples',
};

// export const DefaultMarker = () => (
//   <Wrapper>
//     <MarkerInfoWindowGmapsObj />
//   </Wrapper>
// );

// const styles = ({ maps_allowed }) => css`
//   border: solid 100px black;
//   border-radius: 10px;
//   padding: 16px;
//   cursor: pointer;
//   ${maps_allowed === false &&
//   `
//     width: 100%;
//     height: 100%;
//     position: absolute;

//     background-color: #413F42;
//     color: white;
//     z-index: 100; 
//   `}
// `;


export const MoabitMapWrapper = ( props ) => {
  // console.log('MoabitMapWrapper props: ', props);
  // const getClassName = ( maps_allowed = false ) => {
  //   // eslint-disable-next-line camelcase
  //   const conf = maps_allowed
  //     ? 'maps_opt_in_confirmed' 
  //     : 'maps_opt_in_unconfirmed';

  //   return `mi_maps_wrapper_${conf}`;
  // }

  // eslint-disable-next-line camelcase
  const { map_opt_in_confirmed, isMapView } = props;
  // console.log('maps_allowed: ', map_opt_in_confirmed)
  return (

    <Wrapper style={isMapView? {display: 'block'} : {display: 'none'}}>
      {!map_opt_in_confirmed
        ? <OptIn {...props} />
        : <MoabitMap {...props} />
      }
    </Wrapper>
  )}

const Wrapper = styled.section`
  width: 100vw;
  height: 40vh;
  display: none !important;
  @media(min-width: 768px){
    display: block !important;
  }
`;
