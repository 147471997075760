import React, { useEffect, useState, useRef }from 'react'
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { Link as GatsbyLink  } from 'gatsby'
import { connect } from 'react-redux';
import { ScrollToHOC, ScrollArea } from "react-scroll-to";

import { setIsMapView, 
  setIsCategoryView, 
  setActiveParticipant, 
  activateParticipant, 
  openParticipant, 
  setActivatedParticipantOffsetX,
  filterParticipants
} from '../../../state/actions'

import ArtsAndCraftsCardIcon from '../../CardIcons/ArtsAndCrafts' 
import BusinessCardIcon from '../../CardIcons/Business' 
import GastronomyIcon from '../../CardIcons/Gastronomy' 
import SocialCardIcon from '../../CardIcons/Social' 
import FluidImage from "../../FluidImage"
import placesFromProps from "./helpers"


const Card = ( props ) => {

  
  const { 
    setIsMapView,
    setIsCategoryView,
    setActiveParticipant,
    activateParticipant,
    setActivatedParticipantOffsetX,
    filterParticipants,
    activeCategoryId,
    activeCategoryName,
    activeCategorySlug,
    refs,
    isPhone
  } = props;

  const { 
    place,
    active_participant_id,
    activated_participant_id,
    opened_participant_id,
    activated_participant_offset_x,
  } = props;
  const { slug, uri, title, zip, id, category, shortTitle, heroImage } = place;
  const displayTitle = shortTitle && shortTitle !== '' ? shortTitle : title;
  const catId = category.id;

  // const activated = active && activated_participant_id == id;

  const handleLinkClick = (e, target) => {
    if (typeof window !== 'undefined') {
      if (e) {
        // e.preventDefault()
        setActivatedParticipantOffsetX(e.currentTarget.offsetLeft);
        setActiveParticipant(id)
        activateParticipant(id)
        setIsCategoryView(false)
        setIsMapView(true)
        filterParticipants(activeCategoryId, activeCategoryName, activeCategorySlug)
        openParticipant(id)

      }
    }
  }


  const [inHover, setHover] = useState(false);
  const active = active_participant_id === id || inHover;

  const FancyStyledMobileCardOuter = React.forwardRef((props, ref) => {


    return (
      <StyledMobileCardOuter 
        ref={ref} 
        className={active? 'mi-active' : ''}
        key={slug}
        to={uri}
        title={title}
        onClick={handleLinkClick}
        onMouseEnter={() => {
          console.log('setHover to true')
          setHover(true)}}
        onMouseLeave={() => {
          console.log('setHover to false')
          setHover(false)}}
        id={`mi_card_mobile_${id}`}
      >
        {props.children}
      </StyledMobileCardOuter>
    )}
  );


  // const isActive = filter_category_id === cat.databaseId;
  // console.log('makeing ref in StyleMobileCardOuter', refs[place.id])

  return (
    <FancyStyledMobileCardOuter active={active} ref={refs[place.id]}>
      <StyledMobileCardInner>
        <StyledMobileCardPhoto>
          {heroImage && heroImage[0] ? 
            <FluidImage className="mi-card-image" image={heroImage[0]} style={{marginBottom: '0px'}} /> : null }
        </StyledMobileCardPhoto>
        <StyledMobileCardContent>
          <StyledMobileCardIcon>
            {catId === 9 ? <ArtsAndCraftsCardIcon active={active}  /> : null }
            {catId === 7 ? <SocialCardIcon active={active}  /> : null }
            {catId === 5 ? <BusinessCardIcon active={active}  /> : null }
            {catId === 8 ? <GastronomyIcon active={active}  /> : null }
          </StyledMobileCardIcon>
          <StyledMobileCardText>{displayTitle}</StyledMobileCardText>
        </StyledMobileCardContent>
      </StyledMobileCardInner>
    </FancyStyledMobileCardOuter>
  )
}

const StyledMobileCardContainer = styled.div`
  order: 3;
  flex: 1 0 100vw;
  display: flex; 
  flex-wrap: no-wrap;
  overflow-x: scroll;
  padding: 24px;  
  width: 100vw;
  height: 233px;
  background-color: ${({ theme }) => theme.mi_background_real};
  padding-right: 24px;
  @media(min-width: 768px){
    align-content: flex-start;

    padding: 20px 24px;
    border-top: 2px solid ${({ theme }) => theme.mi_white};
    flex: 1 0 50vw;
    order: 1;
    flex-wrap: wrap;
    overflow-y: scroll;
    width: 50vw;
    height: calc(100vh - 95px);
    height: calc(var(--vh, 1vh) * 100 - 95px);
    /* display: none !important; */
  }

`

const Papa = ScrollToHOC(function(props) {
  // console.log('papa children: ', children); 
  console.log('papa props: ', props);
  const { children, scroll, active_participant_id, activated_participant_offset_x, isPhone, refs } = props;

  useEffect(() => {
    if (active_participant_id && !isPhone) {
      console.log("useEffect >>> active_participant_id", active_participant_id);
      const headerHeight = document.getElementById("foo").offsetTop;
      if(refs[active_participant_id] && refs[active_participant_id].current) {
        const elementTop = refs[active_participant_id].current.offsetTop;
        const scrollToY = elementTop - headerHeight - 44;
        scroll({
          id: "foo",
          smooth: true,
          y: scrollToY
        });
      }

    }
    if (activated_participant_offset_x && isPhone) {
      console.log("useEffect >>> activated_participant_offset_x", activated_participant_offset_x);
      const leftPos = document.getElementById("foo").offsetLeft;
      if(refs[active_participant_id] && refs[active_participant_id].current) {
        const elementLeft = refs[active_participant_id].current.offsetLeft;
        const scrollToX = elementLeft - leftPos -24;
        scroll({
          id: "foo",
          smooth: true,
          x: scrollToX
        });
      }

    // }
    }}, [active_participant_id, activated_participant_offset_x, isPhone ]);

  return (
    <ScrollArea 
      css={css`
      order: 3;
      flex: 1 0 100vw;
      display: flex; 
      flex-wrap: no-wrap;
      overflow-x: scroll;
      padding: 24px;  
      width: 100vw;
      height: 233px;
      background-color: #f2ebe7;
      padding-right: 24px;
      @media(min-width: 768px){
        align-content: flex-start;
    
        padding: 20px 24px;
        border-top: 2px solid #fff;
        flex: 1 0 50vw;
        order: 1;
        flex-wrap: wrap;
        overflow-y: scroll;
        width: 50vw;
        height: calc(100vh - 95px);
        height: calc(var(--vh, 1vh) * 100 - 95px);
        /* display: none !important; */
      }
    `}
      
      className="mi-card-container"
      id="foo"
    >
      {children}
    </ScrollArea>
  );
})

const ParticipantCardsMobile = ( props ) => {
  const places = placesFromProps(props)
  const { activated_participant_offset_x, filter_category_id, active_participant_id, isPhone }  = props;

  const refs = places.reduce((acc, participant) => {
    acc[participant.id] = React.createRef();
    return acc;
  }, {});


  const filteredPlaces = filter_category_id? places.filter(p => p.categoryId === filter_category_id) : places;

  return (
    <>
      <Papa active_participant_id={active_participant_id} activated_participant_offset_x={activated_participant_offset_x} 
      isPhone={isPhone} refs={refs}>
        {filteredPlaces.map((place) => {
          return (
            <Card 
              key={place.id} 
              place={place}
              refs={refs}
              {...props}
            />
          )
        })}
      </Papa>
    </>
  )
}

const mapStateToProps = state => {
  return {
    isMapView: state.map.isMapView,
    isCategoryView: state.view.isCategoryView,
    active_participant_id: state.participants.active_participant_id,
    activated_participant_id: state.participants.activated_participant_id,
    opened_participant_id: state.participants.opened_participant_id,
    activated_participant_offset_x: state.participants.activated_participant_offset_x,
    activeCategoryId: state.participants.active_category.id,
    activeCategoryName: state.participants.active_category.name,
    filter_category_id: state.participants.active_category.id,
    activeCategorySlug: state.participants.activeCategorySlug,
    // ap_opened: state.participants.active_participant.category_opened,
    isPhone: state.responsive.isPhone,
  }
};

const mapDispatchToProps = {
  setIsMapView,
  setIsCategoryView,
  setActiveParticipant,
  activateParticipant,
  openParticipant,
  setActivatedParticipantOffsetX,
  filterParticipants,
};

const StyledMobileCardOuter = styled(GatsbyLink)`
  width: 144px;
  height: 184px;
  
  border-radius: 4px;
  background-color: #ffffff;
  margin-right: 12px;
  border-radius: 4px;
  text-decoration: none;
  &.mi-active{
    background-color: ${({ theme }) => theme.mi_red};
  }
  @media(min-width: 768px) {
    position: relative;
    height: auto;
    &:before {
      display: block;
      content: "";
      width: 100%;
      /* padding-top: (47 / 48) * 100%; */
      padding-top: calc(0.9791666667 * 100%);
    }
    width: 288px;
    /* height: 282px; */
    margin-right: 48px;
    margin-bottom: 48px;

    width: calc(100% - 3rem);
    margin: 1.5rem;
  }

  @media(min-width: 1024px){
    width: calc(50% - 3rem);
    &:before {
      padding-top: calc(1.1 * 100%);
    }
  }

  @media(min-width: 1800px){
    width: calc(33.33% - 3rem);
    margin: 1.5rem;
  }
  /*
  @media(min-width: 2500px){
    width: calc(25% - 3rem);
    margin: 1.5rem;
    &:before {
      padding-top: calc(0.9791666667 * 100%);
    }
  }
  */

`

const StyledMobileCardContent = styled.div`
  height: calc(100% - 81px);
  display: flex;
  flex-direction: column;
  @media(min-width: 768px){
    flex: 1;
  }
`

const StyledMobileCardInner= styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  @media(min-width: 768px){
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
  }
`

const StyledMobileCardPhoto = styled.div`
  width: 144px;
  height: 81px;
  border-radius: 4px 4px 0 0;
  .mi-card-image{
    border-radius: 4px 4px 0 0;
  }
  @media(min-width: 768px){
    // width: 288px;
    // height: 162px;

    width: 100%;
    height: auto;
  }

`
const StyledMobileCardIcon = styled.div`
  height: 32px;
  width: 32px;
  margin: 10px auto 7px;
  display: flex;
  justify-content: center;
  /* one column */
  @media(min-width: 768px){
    margin-top: 15px;
    --minWidthHeight: 32px;
    --maxWidthHeight: 96px;
    --scaler: 5vw;
    width: clamp( var(--minWidthHeight), var(--scaler), var(--maxWidthHeight) );
    height: clamp( var(--minWidthHeight), var(--scaler), var(--maxWidthHeight) );
  }
  /* two columns */
  @media(min-width: 1024px){
    margin-bottom: 0;
    margin-top: 10px;
    --minWidthHeight: 32px;
    --maxWidthHeight: 96px;
    --scaler: 3vw;
    width: clamp( var(--minWidthHeight), var(--scaler), var(--maxWidthHeight) );
    height: clamp( var(--minWidthHeight), var(--scaler), var(--maxWidthHeight) );
  }
  /* three columns */
  @media(min-width: 1800px){
    margin-top: 15px;
    --minWidthHeight: 32px;
    --maxWidthHeight: 96px;
    --scaler: 2vw;
    width: clamp( var(--minWidthHeight), var(--scaler), var(--maxWidthHeight) );
    height: clamp( var(--minWidthHeight), var(--scaler), var(--maxWidthHeight) );
  }
`

const StyledMobileCardText = styled.div`

  font-family: SofiaW01;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0 11px 9px 11px;

  align-items: center;

  /*@media(min-width: 1200px){
    font-size: 22px;
    line-height: 1.55;
  }*/

  @media(min-width: 768px){

    flex: 2 0 auto;
    --minFontSize: 10px;
    --maxFontSize: 22px;
    --scaler: 10vw;
    font-size: clamp( var(--minFontSize), var(--scaler), var(--maxFontSize) );
  }


  @media(min-width: 1024px){
    padding-bottom: 18px;
    --minFontSize: 10px;
    --maxFontSize: 22px;
    --scaler: 1.5vw;
    font-size: clamp( var(--minFontSize), var(--scaler), var(--maxFontSize) );
  }


  @media(min-width: 1380px){
    padding-bottom: 18px;
    --minFontSize: 10px;
    --maxFontSize: 22px;
    --scaler: 8vw;
    font-size: clamp( var(--minFontSize), var(--scaler), var(--maxFontSize) );
  }

  @media(min-width: 1800px){

    --minFontSize: 10px;
    --maxFontSize: 22px;
    --scaler: 10vw;
    font-size: clamp( var(--minFontSize), var(--scaler), var(--maxFontSize) );
  }
`

export default connect(mapStateToProps, 
  mapDispatchToProps,
  null, // mergeProps
  { forwardRef: true }, // options
)(ParticipantCardsMobile)
