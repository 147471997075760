import React from "react";
import styled from '@emotion/styled'

export default function ArtsAndCrafts(props) {
  const { active } = props;
  
  return (
    <StyledSVG 
      active={active}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <g fill="none" fillRule="evenodd">
        <path fill="#0083CA" fillRule="nonzero" d="M21.0202642,13.7456643 L23.8051167,18.5025655 L15.96,26.16 L8.11488328,18.5025655 L10.8997358,13.7456643 L21.0202642,13.7456643 Z" className={active? 'mi-dm-active' : 'mi-dm-inactive'} />
        <path fill="#0083CA" fillRule="nonzero" d="M24.28,9.52 C24.5410183,9.7157637 24.6099267,10.0726332 24.4521789,10.3487839 L24.408,10.416 L22.872,12.464 C22.6599227,12.7467698 22.2587698,12.8040773 21.976,12.592 C21.7149817,12.3962363 21.6460733,12.0393668 21.8038211,11.7632161 L21.848,11.696 L23.384,9.648 C23.5960773,9.36523021 23.9972302,9.30792266 24.28,9.52 Z M8.48384265,9.58676771 L8.536,9.648 L10.072,11.696 L10.1161789,11.7632161 C10.2739267,12.0393668 10.2050183,12.3962363 9.944,12.592 C9.68298173,12.7877637 9.32109268,12.7539923 9.10015735,12.5252323 L9.048,12.464 L7.512,10.416 L7.46782106,10.3487839 C7.3100733,10.0726332 7.37898173,9.7157637 7.64,9.52 C7.90101827,9.3242363 8.26290732,9.35800766 8.48384265,9.58676771 Z M15.96,6.32 C16.2882149,6.32 16.5587246,6.56706572 16.5956943,6.88536248 L16.6,6.96 L16.6,9.52 C16.6,9.87346224 16.3134622,10.16 15.96,10.16 C15.6317851,10.16 15.3612754,9.91293428 15.3243057,9.59463752 L15.32,9.52 L15.32,6.96 C15.32,6.60653776 15.6065378,6.32 15.96,6.32 Z" className={active? 'mi-sh-active' : 'mi-sh-inactive'} />
        <path fill="#FFFFFF" fillRule="nonzero" d="M15.96,19.12 C15.6065378,19.12 15.32,18.8334622 15.32,18.48 C15.32,18.1517851 15.5670657,17.8812754 15.8853625,17.8443057 L15.96,17.84 L20.44,17.84 C20.7934622,17.84 21.08,18.1265378 21.08,18.48 C21.08,18.8082149 20.8329343,19.0787246 20.5146375,19.1156943 L20.44,19.12 L15.96,19.12 Z" className={active? 'mi-ds-active' : 'mi-ds-inactive'} />
      </g>
    </StyledSVG>
  )
}

const StyledSVG = styled.svg`
  width: 100%;
  height: 100%;
  .mi-dm-active{
    fill: #ffffff;
  }
  .mi-dm-inactive{
    fill: #0083CA;
  }
  .mi-sh-active{
    fill: #000000;
  }
  .mi-sh-inactive{
    fill: #0083CA;
  }
  .mi-ds-active{
    fill: #000000;
  }
  .mi-ds-inactive{
    fill: #ffffff;
  }
`
