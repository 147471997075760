/* eslint-disable radix */
import React from 'react';
import styled from '@emotion/styled'
import { bool, func } from 'prop-types';
import { connect } from 'react-redux';

const formattedSize = (size) => {
  // eslint-disable-next-line no-restricted-globals
  return isNaN(parseInt(size)) ? size : `${size}px`
}

const Burger = (props) => {
  const { open, setOpen, responsive } = props
  const padding = '5px 3px';
  const lineHeight = 3;
  const lineSpacing = 4.5;
  const width = 23;
  const top = lineHeight + lineSpacing;
  const height = lineSpacing * 2 + lineHeight * 3
  const formattedWidth = formattedSize(width)
  const formattedHeight = formattedSize(height)
  const borderRadius = 2;

  const isExpanded = !!open;
  const isVisible = true;
  
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <StyledBurger isVisible={isVisible} aria-label="Toggle menu" borderRadius={borderRadius} lineSpacing={lineSpacing} lineHeight={lineHeight} padding={padding} top={top} formattedHeight={formattedHeight} formattedWidth={formattedWidth} aria-expanded={isExpanded} open={open} onClick={() => setOpen(!open)} {...props}>
      <div className='BurgerBox'>
        <div className='BurgerInner' />
      </div>
    </StyledBurger>
  )
}

Burger.propTypes = {
  open: bool.isRequired,
  setOpen: func.isRequired,
};

const mapStateToProps = state => {
  return {
    responsive: state.responsive
  }
};


// export default Burger;
export default connect(mapStateToProps)(Burger)





const StyledBurger = styled.button`
    opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
    @media(min-width: 768px){
      opacity: 0;
      pointer-events: none;
    }
    pointer-events: ${({ isVisible }) => (isVisible ? 'all' : 'none')};
    position: fixed;
    right: 24px;
    top: 26px;

    padding: ${({ padding }) => padding };
    display: inline-block;
    cursor: pointer;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
    outline: 0;
    z-index: 17;

  .BurgerBox{
    width: ${({ formattedWidth }) => formattedWidth};
    height: ${({ formattedHeight }) => formattedHeight};
    display: inline-block;
    position: relative;
  }
  .BurgerInner{
    background-color: ${({ theme }) => theme.mi_black};
    top: 50%;
    margin-top: ${({lineHeight, open}) => (lineHeight / 2) * -1}px;
    transform:  ${({ open }) => (open ? 'rotate(-135deg)' : '')};
    transition-delay: ${({ open }) => (open ? '0.12s' : '0s')};
    transition-duration: 0.22s;
    transition-timing-function:  ${({ open }) => (open ? 'cubic-bezier(0.215, 0.61, 0.355, 1)' : 'cubic-bezier(0.55, 0.055, 0.675, 0.19)')};

    &:before,
    &:after{
      content: '';
      display: block;
    }
    &,
    &:before,
    &:after{
      position: absolute;
      width: 100%;
      background-color: ${({ theme }) => theme.mi_black};
      height: ${({ lineHeight }) => lineHeight}px;
      border-radius: ${({ borderRadius }) => borderRadius}px;
      transition-property: transform;
      transition-duration: 0.15s;
      transition-timing-function: ease;
    }
    &:before{
      top: ${({ open, lineSpacing, lineHeight }) => (open ? 0 : (lineSpacing + lineHeight) * -1)}px;
      opacity: ${({ open }) => (open ? 1 : 1)};
      transition:${({ open }) => (open ? 'top 0.1s ease-out, opacity 0.1s 0.12s ease-out' : 'top 0.1s 0.15s ease-in,opacity 0.1s ease-in')};
    }
    &:after{
      bottom: ${({ open, lineSpacing, lineHeight }) => (open ? 0 : (lineSpacing + lineHeight) * -1)}px;
      transform: ${({ open }) => (open ? 'rotate(-90deg)' : '')};
      transition:${({ open }) => (open ? 'bottom 0.1s ease-out,transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1)' : 'bottom 0.15s 0.1s ease-in, transform 0.12s cubic-bezier(0.55, 0.055, 0.675, 0.19)')};
    }
  }

  span {
    
    width: 2rem;
    height: 0.25rem;
    background: ${({ theme, open }) => open ? theme.primaryDark : theme.primaryLight};
    background: blue;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
    :first-of-type {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }
    :nth-of-type(2) {
      opacity: ${({ open }) => open ? '0' : '1'};
      transform: ${({ open }) => open ? 'translateX(20px)' : 'translateX(0)'};
    }
    :nth-of-type(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`;