const defaults = {
  isCategoryView: false
}

function view(state = defaults, action ) {
  switch(action.type){
  case 'SET_IS_CATEGORY_VIEW':
    return { ...state, isCategoryView : action.isCategoryView};
  default:
    // console.log('REDUCER default view')
    return state;
  }
}

export default view;
