import React from "react";
import styled from '@emotion/styled'

export default function ArtsAndCrafts(props) {
  const { active } = props;
  
  return (
    <StyledSVG 
      active={active}
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <g xmlns="http://www.w3.org/2000/svg" fill="none" fillRule="evenodd">
        <polygon fill="#FFF3CF" fillRule="nonzero" points="22 13 10 13 10.15 25 21.85 25" className={active? 'mi-bg-active' : 'mi-bg-inactive'} />
        <path fill="#FEC110" fillRule="nonzero" d="M16,8 C18.1589313,8 19.9183812,9.71038326 19.997241,11.8500412 L20,12 L20,15.3333333 C20,15.7015232 19.7015232,16 19.3333333,16 C18.9914428,16 18.7096619,15.7426399 18.6711518,15.4110808 L18.6666667,15.3333333 L18.6666667,12 C18.6666667,10.5272407 17.4727593,9.33333333 16,9.33333333 C14.5718697,9.33333333 13.4059474,10.455979 13.3365969,11.8669064 L13.3333333,12 L13.3333333,15.3333333 C13.3333333,15.7015232 13.0348565,16 12.6666667,16 C12.3247761,16 12.0429952,15.7426399 12.0044852,15.4110808 L12,15.3333333 L12,12 C12,9.790861 13.790861,8 16,8 Z" className={active? 'mi-hd-active' : 'mi-hd-inactive'} />
      </g>
    </StyledSVG>
  )
}

const StyledSVG = styled.svg`
  width: 100%;
  height: 100%;
  .mi-hd-active{
    fill: #000000;
  }
  .mi-hd-inactive{
    fill: #FEC110;
  }
  .mi-bg-active {
    fill: #FFF3CF;
  }
  .mi-bg-inactive{
    fill: #FFF3CF;
  }
`
