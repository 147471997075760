import React from 'react'
import styled from '@emotion/styled'
import { StaticQuery, graphql, Link as GatsbyLink } from 'gatsby'
import { connect } from 'react-redux';
// import slugify from '../../utils/slugify'
// import Link from '../Link'
// import './menu.scss'
import { setIsMapView, setIsCategoryView } from '../../state/actions'

const Menu = ( props ) => {
  const { 
    data,
    isMapView,
    setIsMapView,
    setIsCategoryView
  } = props;

  // console.log('\n\n\n===== Menu props: ', props);

  return (
    <StyledMenu className="main-menu">
      <ul>
        {data.map((item) => (
          <li key={item.url}>
            <GatsbyLink 
              className="mi_nav_link"
              to={item.path}
              onClick={() => {
                setIsCategoryView(false)
                setIsMapView(false)}}
            >
              {item.label}
            </GatsbyLink>
          </li>
        ))}
      </ul>
    </StyledMenu>
  )
}

const MainMenu = ( props ) => {
  const { isMapView, setIsMapView, setIsCategoryView } = props
  return (
    <StaticQuery
      query={graphql`
            query {
                wpMenu(name: { eq: "primary" }) {
                    menuItems {
                        nodes {
                            url
                            label
                            target
                            path
                        }
                    }
                }
            }
        `}
      render={(data) => {
      // eslint-disable-next-line react/jsx-props-no-spreading
        return <Menu isMapView={isMapView} data={data.wpMenu.menuItems? data.wpMenu.menuItems.nodes : []} {...props} />
      }}
    />
  )}

const StyledMenu = styled.nav`
  position: fixed;
  right: 0;
  opacity: 0;
  pointer-events: none;
  ul{
    list-style: none;
    display: flex;
    margin-top: 34px;
  }
  li{
    margin-right: 36px;
    &:last-of-type{
      margin-right: 48px;
    }
  }
  .mi_nav_link{
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: SofiaW01;
    font-size: 18px;
    font-weight: bold;
    text-decoration: none;

    line-height: 1.78;
    letter-spacing: 0.5px;

    color: ${({ theme }) => theme.mi_black};
  }
  @media(min-width: 768px){
    opacity: 1;
    pointer-events: all;
  }
`


const mapStateToProps = state => {
  // console.log('mapStateToProps mainmenu: ', state)
  return {
    isMapView: state.map.isMapView
  }
};

const mapDispatchToProps = {
  setIsMapView,
  setIsCategoryView
};

export default connect(mapStateToProps, mapDispatchToProps)(MainMenu)