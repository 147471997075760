import React from 'react'
import styled from '@emotion/styled'
import { StaticQuery, graphql } from 'gatsby'
import { connect } from "react-redux";
// import slugify from '../../utils/slugify'
import { filterParticipants, setIsMapView, setIsCategoryView } from '../../state/actions'
import Link from '../Link'

const List = (props) => {
  const { 
    data , 
    filter_category_id, 
    filter_category_name, 
    filterParticipants ,
    isMapView,
    setIsMapView,
    setIsCategoryView
  } = props
  // console.log('SETIS_MAPIFJDLS: ', setIsMapView)
  const triggerAction = (cat) => {
    setIsMapView(true)
    setIsCategoryView(true)
    if(cat){
      filterParticipants(cat.databaseId, cat.name)
    } else {
      filterParticipants(null)
    }
  }

  const toIgnore = [
    'Weekly Rundown',
    'Design',
    'Inspiration',
    'Uncategorised',
    'UX',
  ]
  const Wrapper = styled.section`
  display: none !important;
  @media(min-width: 767px){
    display: block !important;
  }
`;


  return (
    <Wrapper>
      {/* <p>Filter by category:</p> */}
      <ul>
        {data.map((cat) => {
          if (!toIgnore.includes(cat.name) && cat.nodeType === 'ParticipantCategory') {
            return (
              <li key={cat.uri}>
                {/* <pre>{JSON.stringify(cat, null, 4)}</pre> */}
                <Link
                  to={cat.uri}
                  onClick={() => triggerAction(cat)}
                >
                  {cat.name}
                </Link>
              </li>
            )
          }
          if(!toIgnore.includes(cat.name) && cat.nodeType === 'Taxonomy') {
            return (
              <li key={cat.archivePath}>
                {/* <pre>{JSON.stringify(cat, null, 4)}</pre> */}
                <Link
                  to={cat.archivePath}
                  onClick={() => triggerAction(null)}
                >
                  Alle
                </Link>
              </li>
            )
          }
          return false
        })}
      </ul>
    </Wrapper>
  )
}

const FilterCategories = ( props ) => {
  const { filter_category_id, filter_category_name, filterParticipants, setIsMapView, setIsCategoryView } = props;
  // console.log('FilterCategories props', props)
  return (
    <StaticQuery
      query={graphql`
            query {
              allWpParticipantCategory {
                  nodes {
                    id
                    name
                    databaseId
                    uri
                    nodeType
                  }
              }
              allWpTaxonomy( filter: { name: { eq: "moabit_participant_category" }} ) 
              {
                nodes {
                  archivePath
                  name
                  nodeType
                  restBase
                }
              }
            }
        `}
      render={(data) => <List filter_category_id={filter_category_id} filter_category_name={filter_category_name} filterParticipants={filterParticipants} setIsMapView={setIsMapView} setIsCategoryView={setIsCategoryView} data={[ ...data.allWpTaxonomy.nodes, ...data.allWpParticipantCategory.nodes ]} />}
    />
  )}

const mapStateToProps = state => {
  // console.log('mapStateToProps FilterCategories: ', state)
  return {
    filter_category_id: state.participants.active_category.id,
    filter_category_name: state.participants.active_category.name,
    isMapView: state.map.isMapView,
    isCategoryView: state.view.isCategoryView
  }
};


// export default FilterCategories
const mapDispatchToProps = {
  filterParticipants,
  setIsMapView,
  setIsCategoryView
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterCategories);