const theme = {
  mi_red: '#ea5223',
  mi_yellow: '#fec110',
  mi_green: '#57c18b',
  mi_blue: '#0083c8',
  mi_white: '#ffffff',
  mi_black: '#000000',
  mi_background_real: '#f2ebe7',
  mi_background: '#00ff00',
  mobile_p: '375px', 
  mobile_l: '576px', 
  tablet_p: '768px', 
  tablet_l: '1024px',
  laptop: '1280px', 
  desktop: '1440px',
}

export { theme as default }
