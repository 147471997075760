/* eslint-disable no-nested-ternary */
import React from 'react'
import MapLayout from './map'

const Layout = ( props ) => {
  // console.log('layouts index props: ', props)
  const { children, pageContext, location, data } = props;

  const participants = data && data.allWpParticipant
  let nodes = participants && participants.nodes;
  

  const { categoryDatabaseId : id, name } = pageContext;
  // console.log('\n\n\n PAGE CONTEXT:  ', JSON.stringify(id, name))
  // console.log('\n\n\n NODES:  ', JSON.stringify(nodes, null, 4))

  if(id){
    nodes = nodes.filter(p => p.participantCategories.nodes[0].databaseId === id)
  }
  // const places = data
  //   ? participants
  //     ? nodes
  //       ? participants.nodes : []
  //     : []
  //   : []
  const places = nodes || []

  // console.log('location: ', location);
  // console.log('places: ', JSON.stringify(places, null, 4))
  return <MapLayout pageContext={pageContext} places={places} pathName={location.pathname}>{children}</MapLayout>
}

export default Layout
