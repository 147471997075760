import { navigate } from "@reach/router"

function setCookie(e, t, i) {
  let o = "";
  if (i) {
    const n = new Date;
    n.setTime(n.getTime() + 24 * i * 60 * 60 * 1e3)
    o = `; expires=${  n.toUTCString()}`
  }
  if(typeof document !== 'undefined') {
    document.cookie = `${e  }=${  t || ""  }${o  }; path=/`
  }
}

// export const LOAD_GMAPS_API = 'LOAD_GMAPS_API';
// export function f(gapi){
//   return{
//     type: LOAD_GMAPS_API,
//     gapi
//   }
// }

// export const ALLOW_MAPS = 'ALLOW_MAPS';
// export const allowMaps = (allow) => {
//   console.log('index action: allow param: ', allow);
//   // setCookie('gm-allowed', '', 3650);  
//   setCookie(allow, '', 3650);
//   return {
//     type: ALLOW_MAPS,
//     allow,
//   };
// };
export const HYDRATE_PLACES = 'HYDRATE_PLACES';
export const hydratePlaces = () => {
  // console.log('index action: increment');
  return {
    type: HYDRATE_PLACES,
  };
};

export const INCREMENT = 'INCREMENT';
export const increment = () => {
  // console.log('index action: increment');
  return {
    type: INCREMENT,
  };
};

export const UPDATE_ROUTE = 'UPDATE_ROUTE';
export const updateRoute = (location) => {
  // navigate(location.href);
  console.log('updateRoute: location: ', JSON.stringify(location, null, 4))
  return {
    type: UPDATE_ROUTE,
    href : location.pathname
  };
};

export const INITIALIZE_PARTICIPANT_CATEGORY = 'INITIALIZE_PARTICIPANT_CATEGORY';
export const initParticipantCategory = ( participantCategoryId = null, participantCategoryName = '' ) => {
  // console.log('index action: initStateFromQuery');
  // console.log('participantCategoryId: ', participantCategoryId)
  return {
    type: INITIALIZE_PARTICIPANT_CATEGORY,
    participant_category_id: participantCategoryId,
    participant_category_name: participantCategoryName
  };
};

export const SET_INIATLIZED_TO_TRUE = 'SET_INIATLIZED_TO_TRUE';
export const setInitalizedToTrue = () => {
  // console.log('index action: setInitalizedToTrue');
  return {
    type: SET_INIATLIZED_TO_TRUE,
  };
};

export const SET_IS_MAP_VIEW = 'SET_IS_MAP_VIEW';
export const setIsMapView = ( isMapView = false ) => {
  // console.log('index action: setIsMapView');
  return {
    type: SET_IS_MAP_VIEW,
    isMapView
  };
};

export const SET_IS_CATEGORY_VIEW = 'SET_IS_CATEGORY_VIEW';
export const setIsCategoryView = ( isCategoryView = false ) => {
  // console.log('index action: setIsCategoryView');
  return {
    type: SET_IS_CATEGORY_VIEW,
    isCategoryView
  };
};

export const OPT_IN_GOOGLE_MAPS = 'OPT_IN_GOOGLE_MAPS';
export const optInMaps = () => {
  // console.log('index action: optInGoogleMaps');
  setCookie('mi_allow_gmaps', '', 3650);
  return {
    type: OPT_IN_GOOGLE_MAPS,
  };
};

export const FILTER_PARTICIPANTS = 'FILTER_PARTICIPANTS';
export const filterParticipants = (participantCategoryId = null, participantCategoryName = '', participantCategorySlug = '' )  => {
  // console.log('index action: FILTER_PARTICIPANTS');
  // console.log('participantCategoryId: ', participantCategoryId)
  return {
    type: FILTER_PARTICIPANTS,
    participant_category_id: participantCategoryId,
    participant_category_name: participantCategoryName,
    participant_category_slug: participantCategorySlug
  };
};

export const SET_CURRENT_PARTICIPANTS = 'SET_CURRENT_PARTICIPANTS';
export const setCurrentParticipants = (participants = [])  => {
  // console.log('index action: FILTER_PARTICIPANTS');
  // console.log('participantCategoryId: ', participantCategoryId)
  return {
    type: SET_CURRENT_PARTICIPANTS,
    participants,
    // participant_category_id: participantCategoryId
  };
};

export const SET_ACTIVE_PARTICIPANT = 'SET_ACTIVE_PARTICIPANT';
export const setActiveParticipant = (participantId = null, participantCategoryId = null)  => {
  // console.log('index action: FILTER_PARTICIPANTS');
  // console.log('participantCategoryId: ', participantCategoryId)
  return {
    type: SET_ACTIVE_PARTICIPANT,
    active_participant_id: participantId,
    // participant_category_id: participantCategoryId
  };
};

export const ACTIVATE_PARTICIPANT = 'ACTIVATE_PARTICIPANT';
export const activateParticipant = (participantId = null)  => {
  // console.log('index action: FILTER_PARTICIPANTS');
  // console.log('participantCategoryId: ', participantCategoryId)
  return {
    type: ACTIVATE_PARTICIPANT,
    activated_participant_id: participantId,
  };
};


export const SET_SINGLE_PARTICIPANT_DATA = 'SET_SINGLE_PARTICIPANT_DATA';
export const setParticipantData = (participantData = null)  => {
  // console.log('index action: FILTER_PARTICIPANTS');
  // console.log('participantCategoryId: ', participantCategoryId)
  return {
    type: SET_SINGLE_PARTICIPANT_DATA,
    participant_data: participantData,
  };
};

export const SET_ACTIVATED_PARTICIPANT_OFFSET_X = 'SET_ACTIVATED_PARTICIPANT_OFFSET_X';
export const setActivatedParticipantOffsetX = (participantOffsetX = null)  => {
  // console.log('index action: FILTER_PARTICIPANTS');
  // console.log('participantCategoryId: ', participantCategoryId)
  return {
    type: SET_ACTIVATED_PARTICIPANT_OFFSET_X,
    activated_participant_offset_x: participantOffsetX,
    // participant_category_id: participantCategoryId
  };
};


export const OPEN_PARTICIPANT = 'OPEN_PARTICIPANT';
export const openParticipant = (participantId = null)  => {
  // console.log('index action: FILTER_PARTICIPANTS');
  // console.log('participantCategoryId: ', participantCategoryId)
  return {
    type: OPEN_PARTICIPANT,
    opened_participant_id: participantId,
  };
};

// export const FINISH_REACT_MAPS_API_LOAD = 'FINISH_REACT_MAPS_API_LOAD';
// export const finishReactMapsApiLoad = ( { map, mapsApi } ) =>{
//   return{
//     type: FINISH_REACT_MAPS_API_LOAD,
//     map,
//     mapsApi
//   }
// }

// export const RESIZE_MAP_CONTAINER = 'RESIZE_MAP_CONTAINER';
// export const resizeMapContainer = ( { mapSize } ) =>{
//   return{
//     type: RESIZE_MAP_CONTAINER,
//     mapSize
//   }
// }

// export const CHANGE_BOUNDS_MAP = 'CHANGE_BOUNDS_MAP';
// export const changeBounds = ({center, zoom, bounds, marginBounds}) => {
//   return {type: CHANGE_BOUNDS_MAP,
//     center, zoom, bounds, marginBounds};
// }

// export const FIT_BOUNDS = 'FIT_BOUNDS';
// export const fitBounds = ({center, zoom }) => {
//   return {type: FIT_BOUNDS,
//     center, zoom };
// }

// export const SCROLL_TO_PARAGRAPH = 'SCROLL_TO_PARAGRAPH';
// export const scrollToParagraph = paragraphId => ({
//   type: SCROLL_TO_PARAGRAPH,
//   paragraphId,
// });
// export const scrollToHeader = () => ({ type: SCROLL_TO_HEADER });
