import React from "react";
import styled from '@emotion/styled'

export default function Back(props) {
  const { active } = props;
  // const backIconRef = React.createRef(null);
  // const [ isActive, setActive ] = useState(false)

  // useEffect(() =>{
  //   if(active){
  //     console.log('now is active')
  //     setActive(true);
  //     // setTimeout(()=>{
  //     //   if(backIconRef){
  //     //     backIconRef.current.addCLass(
  //     //       'activated-end'
  //     //     )
  //     //   }
  //     // }, 300)
  //   } else {
  //     console.log('now is not active')
  //     setActive(false);
  //     // if(backIconRef){
  //     //   backIconRef.current.removeClass(
  //     //     'activated-end'
  //     //   )
  //     // }
  //   }
  // })
  return (
    <StyledSVG 
      active={active}
      xmlns="http://www.w3.org/2000/svg"
      width="50" 
      height="50" 
      viewBox="0 0 50 50"
      // ref={backIconRef}
    >
      <g fill="none" fillRule="evenodd">
        <circle cx="25" cy="25" r="25" fill="#FFF" />
        <g>
          <path d="M0 0L24 0 24 24 0 24z" transform="translate(13 13)" />
          <path fill="#000" fillRule="nonzero" d="M7.828 11L20 11 20 13 7.828 13 13.192 18.364 11.778 19.778 4 12 11.778 4.222 13.192 5.636z" transform="translate(13 13)" />
        </g>
      </g>
    </StyledSVG>
  )
}

const StyledSVG = styled.svg`
  width: 100%;
  height: 100%;
  .mi-bg-active{
    fill: #FEC110;
  }
  .mi-bg-inactive{
    fill: #ffffff;
  }
  .mi-ba-active{
    fill: #ffff;
  }
  .mi-ba-inactive{
    fill: #E9D8CF;
  }
  .mi-hd-active{
    fill: #000000;
  }
  .mi-hd-inactive{
    fill: #AE9C93;
  }

`
/*
  The following does not work
  It seams like react does re-render the whole filterbar, 
  when the action "filterParticipants" is called.
  Trying to find a solution for an animated transition of the icons
  is to time-consuming for now


  Idea:

  Do not connect the Filterbar component to redux,
  but connect every single FilterItem, so not the whole
  component is re-rendered on a prop change
*/


// const StyledDiv = styled.div`
//   width: 100%;
//   height: 100%;

//   transform: ${({ isActive }) => isActive ? 'translateX(0)' : 'translateX(50%)'};
//   transition-timing-function: ${({ isActive }) => isActive ? 'cubic-bezier(0.0, 0.0, 0.2, 1)' : 'cubic-bezier(0.2, 0, 1, 0.9)'};
//   transition-duration: ${({ isActive }) => isActive ? '1250ms' : '1200ms'};
//   transition-property: transform;
// `


