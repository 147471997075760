/* eslint-disable camelcase */
import React from "react"
import { Provider } from "react-redux"
import store from './src/state/createStore';

// import createStore from "./src/state/createStore"
// import hydratePlaces from './src/state/actions';
// import * as ActionCreators from './src/state/actions';
import { mediaQueryTracker} from './src/utils/media-query-tracker';

export default ( props ) => {
  // console.log('\n\n\n\n ======= WRAP ROOT ELEMENT ====== \n\n\n')
  const { element } = props;
  // console.log('wrap-with-provider props: ', props);
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  // const store = createStore( rootReducer, initialState )

  mediaQueryTracker({
    debounceTime: 150,
    queries: [
      {isPhone : "screen and (max-width: 767px)"},
      {isTablet: "screen and (min-width: 768px) and (max-width: 1024px)"},
      {isDesktop: "screen and (min-width: 1025px)"}
    ],
    windowDimensions: {
      doTrack: true,
      innerHeight: 0,
      innerWidth: 0
    }
  }, store.dispatch );
  // const { dispatch } = store
  // const boundHydratePlaces = bindActionCreators(ActionCreators, dispatch)
  // console.log('boundHydratePlaces: ', boundHydratePlaces)
  // const action = boundHydratePlaces.filterParticipants()
  // dispatch(action)
  return (
    <Provider store={store}>
      {element}
    </Provider>
  )
}
