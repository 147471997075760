import React, { useEffect, useState, useRef }from 'react'
// import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { Link as GatsbyLink  } from 'gatsby'
import { Container, Button, Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import IconFacebook from '../../../images/overlay/icon-facebook.svg'
import IconInstagram from '../../../images/overlay/icon-instagram.svg'
import IconTwitter from '../../../images/overlay/icon-twitter.svg'
import MapIcon from '../../../images/overlay/overlay-icon-map.svg'
import IconPhone from '../../../images/overlay/overlay-icon-phone.svg'
import IconStreet from '../../../images/overlay/overlay-icon-pin.svg'
import IconWeb from '../../../images/overlay/overlay-icon-world.svg'
import { initParticipantCategory, setInitalizedToTrue, setIsMapView, setIsCategoryView, updateRoute, setActiveParticipant, activateParticipant, setCurrentParticipants, setActivatedParticipantOffsetX,filterParticipants } from '../../../state/actions'
import FluidImage from "../../SingleImage"



const MapButtonMobile = styled(GatsbyLink)`
  display: flex;
  width: 80px;
  height: 40px;


  border: solid 2px #f6efeb;
  background-color: #f6efeb;
  border-radius: 20px;

  font-family: SofiaW01;
  font-size: 11px;
  font-weight: bold;


  line-height: 1.4;


  color: var(--black);
  position: relative;
  align-content: center;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  img{
    margin-left: 4px;
  }

  position: fixed;
  top: 19px;
  right: 18px;

  pointer-events: all;
  @media(min-width: 768px){
    display: none;
  }
`




const ParticipantSingle = (props) => {
  const [showButton, setShowButton] = useState(true);
  const [showMessage, setShowMessage] = useState(false);

  const { data, pageContext, isStateInitialized, 
    isMapView, isCategoryView, activated_participant_id,
    initParticipantCategory, setInitalizedToTrue,
    setIsMapView, setIsCategoryView, updateRoute, setActiveParticipant, activateParticipant, participants, setCurrentParticipants, setActivatedParticipantOffsetX, lastCategoryRoute, activeCategoryId, activeCategorySlug, filterParticipants, participantData, browserWidth } = props;



  console.log('\n\n\n SINGLE VIEW lastCategoryRoute: ',  lastCategoryRoute)
  const isCatV = isCategoryView; // || lastCategoryRoute === '/erkunden/'
  const isSingleView =  isMapView && !isCatV;

  const [showParticipantContainer, setShowParticipantContainer] = useState(isSingleView);
  
  // const getTimeout = (width) => {
  //   return  width && width < 768 ? { enter: 2300, exit: 1300}  : {enter: 2800, exit: 1800}
  // }

  const getTheTimeout = (width) => {
    // return  width && width < 768 ? 400  : 900
    const newTimeOut = (width && width < 768) ? { enter: 2300, exit: 1300}  : {enter: 2800, exit: 1800}
    console.log('new Timeout: ', newTimeOut);
    return  newTimeOut
  }

  const timeout = getTheTimeout(browserWidth)
  
  const [overlayTimeout, setOverlayTimeout] = useState(timeout);
  
  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      console.log('mounted: isCategoryView', isCatV)
      setShowParticipantContainer(isMapView && !isCatV)

      // setOverlayTimeout(getTheTimeout(browserWidth))
    } else {
      console.log('upat3d: isCategoryView', isCatV)
      setShowParticipantContainer(isMapView && !isCatV)
      // setOverlayTimeout(getTheTimeout(browserWidth))
    }
    if(browserWidth){
      console.log('browserWidth: ', browserWidth)
      // setOverlayTimeout(getTheTimeout(browserWidth))
    }
  })

  // const dynamicStyle = (props) => css`
  //   background-color: ${props.isCategoryView ? 'rgba(255,255,0,0.2)' : 'rgba(255,0,0,0.2)'};
  //   pointer-events: ${props.isCategoryView ? 'none' : 'all'};
  //   `

  console.log('ParticipantSingle isCatV: ', isCatV)
  console.log('ParticipantSingle isSingleView: ', isSingleView)
  const ParticipantContainer= styled.div`

  width: 100vw;
  height: calc(100vh - 80px);
  height: calc(var(--vh, 1vh) * 100 - 80px);
  position relative;
  z-index: 8;
  top 0;
  left: 0;

  overflow-y: scroll;

  position: absolute;
  top: 80px;
  transition-property: all;
  transition-duration: 5s;

`;


  // const {
  //   id,
  //   databaseId,
  //   title,
  //   slug,
  //   uri,
  //   participantCategories,
  //   mi_participant
  // } = data.wpParticipant

  // const {
  //   miParticipantSubheadline,
  //   miParticipantPhone,
  //   miParticipantOpeningTimes,
  //   miParticipantOpeningTimesTextarea,
  //   miParticipantContent,
  //   miParticipantPrivateWebsite,
  //   miParticipantFacebook,
  //   miParticipantInstagram,
  //   miParticipantTwitter,
  //   miParticipantGeodata,
  //   miParticipantHeroSlider,
  // } = mi_participant




  // let SomeComponent = styled('div')`${dynamicStyle}` 
  // const { isCategoryView = false } = props;


  // const getTransitionTimingFunction = (isCategoryView, isSingleView) => {
    
  //   if(isCategoryView){
  //     return 'cubic-bezier(0.215, 0.61, 0.355, 1)'
  //   }
  //   if(isSingleView){
  //     return 'cubic-bezier(0.55, 0.055, 0.675, 0.19)'
  //   }
  //   return 'cubic-bezier(0.215, 0.61, 0.355, 1)'
  // }
  const StyleFluidImageWrapper = styled.div`   
  flex: 1 0 100%;
`


  const StreetWebPhone = styled.div`
  // padding: 0 24px;
  display: flex;
  justify-content: flex-start;
  padding: 0 24px;
  flex-wrap: wrap;
  @media(min-width: 768px){
    flex-wrap: nowrap;
    padding: 5px 48px;
    justify-content: flex-start;
  }
`

  const Street = styled.div`
    position: relative;
    &::before{
      background: url(${IconStreet});
      background-repeat: no-repeat;
      background-position: center 2px;
      background-size: contain;
      content: ' ';
      position: absolute;
      width: 18px;
      height: 21px;
      left: 0;
    }
    height: 32px;
    display: flex;
    align-items: center;
    padding-left: 22px;

    font-family: SofiaW01;
    font-size: 12px;
    line-height: 1.18;
    margin-right: 26px;
    text-decoration: underline;


    @media(min-width: 768px){
      font-size: 14px;
      line-height: 1.29;
    }
`

  const Web = styled.div`
  position: relative;
  &::before{
    background: url(${IconWeb});
    background-repeat: no-repeat;
    background-position: center 1px;
    background-size: contain;
    content: ' ';
    position: absolute;
    width: 18px;
    height: 18px;
    left: 0;
  }
  height: 32px;
  display: flex;
  align-items: center;
  padding-left: 22px;

  font-family: SofiaW01;
  font-size: 12px;
  line-height: 1.18;
  margin-right: 26px;
  text-decoration: underline;

  @media(min-width: 768px){
    font-size: 14px;
    line-height: 1.29;
  }
`

  const Phone = styled.div`
  position: relative;
  &::before{
    background: url(${IconPhone});
    background-repeat: no-repeat;
    background-position: center 2px;
    background-size: contain;
    content: ' ';
    position: absolute;
    width: 18px;
    height: 21px;
    left: 0;
  }
  height: 32px;
  display: flex;
  align-items: center;
  padding-left: 22px;

  font-family: SofiaW01;
  font-size: 12px;
  line-height: 1.18;
  margin-right: 26px;
  text-decoration: underline;
  margin-right: 0;

  @media(min-width: 768px){
    font-size: 14px;
    line-height: 1.29;
  }
`
  const SingleHeadline = styled.h2`
  font-family: Rockwell;

  font-weight: bold;

  padding: 0 24px;
  margin-bottom: 0;
  font-size: 22px;
  line-height: 1.3;
  @media(min-width: 768px){
    padding: 0 48px;
    margin-top: 56px;
    font-size: 36px;
    line-height: 1.28;
    letter-spacing: normal;
  }
`

const SingleSubHeadline = styled.span`
  display: flex;
  height: 32px;
  font-family: SofiaW01;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  padding: 0 24px;
  color: ${({ theme }) => theme.mi_red};
  @media(min-width: 768px){
    padding: 0 48px;
  }
`

  const SingleContent = styled.div`
  padding: 0 24px 39px 24px;
  p{
    font-family: SofiaW01;
    font-size: 16px;
    line-height: 1.5;
    margin: 0;
    max-width: 624px;
  }
  ul, li {
    font-family: SofiaW01;
    font-size: 16px;
    line-height: 1.5;
    margin: 0;
    max-width: 624px;
  }
  h3{
    font-family: SofiaW01;
    font-weight: bold;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.53px;
    text-transform: uppercase;
    margin-bottom: 24px;
    margin-top: 24px;
    max-width: 624px;
    &:first-of-type{
      margin-top: 26px;
    }
  }
  @media(min-width: 768px){
    padding: 0 50px 72px 48px;
    p, ul, li{
      font-size: 18px;
      line-height: 1.78;
    }
    p{
      margin-bottom: 32px;
    }
    h3{
      letter-spacing: 0.6px;
      font-size: 18px;
      &:first-of-type{
        margin-top: 54px;
      }
    }
  }
`

const MetaContainerWrap = styled.div`
border-top: 2px solid ${({ theme }) => theme.mi_background_real};
  &:last-of-type{
    border-bottom: 2px solid ${({ theme }) => theme.mi_background_real};
  }
`

const PictureCreditContainerWrap = styled.div`
border-top: 2px solid ${({ theme }) => theme.mi_background_real};
&:last-of-type{
  border-bottom: 2px solid ${({ theme }) => theme.mi_background_real};
}
`


  const MetaContainer = styled.div`
  max-width: 624px;
padding: 39px 24px 48px 24px;
@media(min-width: 768px){
  margin: 33px 48px 42px 48px;
  padding: 0;
  display: flex;
}`

const PictureCreditContainer = styled.div`
max-width: 624px;
padding: 39px 24px 48px 24px;

p{
  font-family: SofiaW01;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  margin: 0;
}

@media(min-width: 768px){
  padding: 33px 48px 42px 48px;
  display: flex;
}`


const OpeningContainer = styled.div`
@media(min-width: 768px){
  flex: 1 0 auto;
}
`

const MoreLinksContainer = styled.div`
@media(min-width: 768px){
  flex: 1 0 auto;
}
`
 
  const OpeningHeadline = styled.h3`
  font-family: SofiaW01;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: normal;
  margin-top: 0;
  margin-bottom: 22px;
`
  const OpeningItemContainer = styled.ul`
  list-style: none;
display:flex;
flex-wrap: wrap;
justify-content: space-between;
max-width: 200px;
margin-bottom: 36px;
padding-left: 0;
@media(min-width: 768px){
  margin-bottom: 0;
}
`

  const OpeningItem = styled.li`
font-family: SofiaW01;
font-size: 16px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: normal;
display: flex;
flex: 1 0 100%;

.left{
  flex: 1 0 35%;
}
.right{
  flex: 1 0 65%;
}
&.hasAddition{
  &:last-of-type{
    margin-bottom: 24px;
  }
}

`

const OpeningItemAddition = styled.div`
font-family: SofiaW01;
font-size: 16px;
line-height: 1.5;
letter-spacing: normal;
display: flex;
flex: 1 0 100%;
`



  const FacebookTwitterInsta = styled.div`
padding: 0;
display: flex;
display: flex;
`

  const Facebook = styled.a`
  position: relative;
  display: block;
  &::before{
    background: url(${IconFacebook});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    content: ' ';
    position: absolute;
    width: 22px;
    height: 22px;
    left: 0;
  }
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
`
  const Twitter = styled.a`
  position: relative;
  display: block;
  &::before{
    background: url(${IconTwitter});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    content: ' ';
    position: absolute;
    width: 22px;
    height: 22px;
    left: 0;
  }
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
`
  const Instagram = styled.a`
  position: relative;
  display: block;
  &::before{
    background: url(${IconInstagram});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    content: ' ';
    position: absolute;
    width: 22px;
    height: 22px;
    left: 0;
  }
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
`
  console.log('participantData: ', participantData)


  const hasOpeningTimes = participantData ? participantData.miParticipantOpeningTimes && 
                          participantData.miParticipantOpeningTimes.length ||
                          participantData.miParticipantOpeningTimesTextarea : false

  const hasOpeningTimesAndAddition = participantData ? participantData.miParticipantOpeningTimes && 
                          participantData.miParticipantOpeningTimes.length &&
                          participantData.miParticipantOpeningTimesTextarea : false

  const hasMoreLinks = participantData ? participantData.miParticipantFacebook || 
                        participantData.miParticipantTwitter ||
                        participantData.miParticipantInstagram : false


  return (
    <div
      style={
        {
          width: '100vw',
          height: '100%',
          zIndex: '8',
          left: '0',
          overflowY: 'scroll',
          position: 'absolute',
          pointerEvents: 'none'
        }
      }
    >
      <CSSTransition
        in={showParticipantContainer}
        timeout={overlayTimeout}
        classNames="misingle"
        unmountOnExit
      >
        <div className="misingle">
          {participantData ? (
            <>
              <StyleFluidImageWrapper>
                <FluidImage image={participantData.miParticipantHeroSlider[0]} style={{marginBottom: '96px', marginTop:'96px'}} />
              </StyleFluidImageWrapper>
              <StreetWebPhone>
                {participantData.miParticipantGeodata.streetName ? (
                  <Street>
                    {participantData.miParticipantGeodata.streetName} 
                    {' '}
                    {participantData.miParticipantGeodata.streetNumber}
                  </Street>
                )
                  : null}
                {participantData.miParticipantPrivateWebsite ? (
                  <Web><a rel="noopener noreferrer" target="_blank" href={participantData.miParticipantPrivateWebsite}>Website</a></Web>
                ) : null}
                {participantData.miParticipantPhone ? (
                  <Phone>{participantData.miParticipantPhone}</Phone>
                ) : null}
              </StreetWebPhone>

              <SingleHeadline>{participantData.title}</SingleHeadline>
              {participantData.miParticipantSubheadline?
                <SingleSubHeadline>{participantData.miParticipantSubheadline}</SingleSubHeadline>
                : null}
              
              <SingleContent key={`single_content_${participantData.id}`} dangerouslySetInnerHTML={{ __html: participantData.miParticipantContent }} />
              <MetaContainerWrap>
                <MetaContainer>
                  {hasOpeningTimes ? (
                    <OpeningContainer>
                      <OpeningHeadline>Öffnungszeiten</OpeningHeadline>
                      <OpeningItemContainer>
                        {participantData.miParticipantOpeningTimes && participantData.miParticipantOpeningTimes.length ?
                          (
                            <>
                              {participantData.miParticipantOpeningTimes.map((o,index) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <OpeningItem className={hasOpeningTimesAndAddition ? 'hasAddition' : ''} key={`otimes_${participantData.id}_${index}`}>
                                  <div className="left">{o.miParticipantOpeningTimesDay}</div>
                                  <div className="right">{o.miParticipantOpeningTimesTimeframe}</div>
                                </OpeningItem>
                              ))}
                            </>

                          ) : null}

                        {participantData.miParticipantOpeningTimesTextarea?  <OpeningItemAddition>{participantData.miParticipantOpeningTimesTextarea}</OpeningItemAddition> : null}
                      </OpeningItemContainer>
                    </OpeningContainer>
                  ) : null}

                  {
                    hasMoreLinks ? (
                      <MoreLinksContainer>
                        <OpeningHeadline>Weitere Links</OpeningHeadline>

                        <FacebookTwitterInsta>
                          {participantData.miParticipantFacebook ? (
                            <>
                              <Facebook 
                                rel="noopener noreferrer" 
                                target="_blank" 
                                href={participantData.miParticipantFacebook}
                                title="Facebook"
                              />
                            </>
                          ) : null}
                          {participantData.miParticipantTwitter ? (
                            <>
                              <Twitter
                                rel="noopener noreferrer" 
                                target="_blank" 
                                href={participantData.miParticipantTwitter} 
                                title="Twitter"
                              />
                            </>
                          ) : null}
                          {participantData.miParticipantInstagram ? (
                            <Instagram
                              rel="noopener noreferrer" 
                              target="_blank" 
                              href={participantData.miParticipantInstagram} 
                              title="Instagram"
                            />
                          ) : null}
                        </FacebookTwitterInsta>
                      </MoreLinksContainer>
                    ) : null
                  }

                </MetaContainer>
              </MetaContainerWrap>
              {participantData.miPictureCredits? (
                <PictureCreditContainerWrap>
                  <PictureCreditContainer>
                    <p>{participantData.miPictureCredits}</p>
                  </PictureCreditContainer>
                </PictureCreditContainerWrap>
              ) : null}
              
            </>
          ) : null}
          <MapButtonMobile to={lastCategoryRoute || activeCategorySlug || '/erkunden/'}>
            Karte   
            <img src={MapIcon} alt="" />
          </MapButtonMobile>
        </div>
      </CSSTransition>

    </div>
  )

}

const mapStateToProps = state => {
  // console.log('mapStateToProps participantCategoryPage: ', state)
  return {
    isStateInitialized: state.isStateInitialized,
    lastCategoryRoute: state.route.href,
    participants: state.participants.participants,
    isMapView: state.map.isMapView,
    isCategoryView: state.view.isCategoryView,
    activated_participant_id: state.participants.activated_participant_id,
    activeCategoryId: state.participants.active_category.id,
    activeCategorySlug: state.participants.active_category.slug,
    participantData: state.participants.participantData,
    browserWidth: state.responsive.innerWidth,
  }
};

const mapDispatchToProps = {
  initParticipantCategory,
  setInitalizedToTrue,
  setIsMapView,
  setIsCategoryView,
  updateRoute,
  setActiveParticipant, 
  activateParticipant,
  setCurrentParticipants,
  setActivatedParticipantOffsetX,
  filterParticipants
};


export default connect(mapStateToProps, mapDispatchToProps)(ParticipantSingle)


