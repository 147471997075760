import React from 'react';
import styled from '@emotion/styled'
import { Link as GatsbyLink  } from 'gatsby'
import { connect } from "react-redux";
// import bg1x from '../../../images/opt-in/map-opt-in.png'
// import bg2x from '../../../images/opt-in/map-opt-in@2x.png'
import bg3x from '../../../images/opt-in/map-opt-in@3x.png'
import { optInMaps, setIsMapView, setIsCategoryView } from '../../../state/actions'



const OptInDialogMobile = (props) => {
  const { map_opt_in_confirmed, optInMaps, setIsMapView, setIsCategoryView } = props;

  return (
    <OptInWrapper>
      <OptInContent>
        <OptInHeadline>Zustimmung für das Laden von Kartenmaterial</OptInHeadline>
        <OptInText>
          An dieser Stelle findest du eine Karte mit externen Inhalten von Google Maps. Damit diese Inhalte geladen werden können, benötigen wir deine Zustimmung. 
          Mehr dazu in unserer  
          {' '}
          <GatsbyLink 
            to="/datenschutzerklaerung/" 
            title="Zur Datenschutzerklärung" 
            onClick={() => {
              setIsCategoryView(false)
              setIsMapView(false)}}
          >
            Datenschutzerklärung
          </GatsbyLink>
          .
        </OptInText>
        <OptInButton type="submit" onClick={optInMaps}>Ich stimme zu</OptInButton>
      </OptInContent>
    </OptInWrapper>
  )}


const OptInWrapper = styled.section`
  background-image: url(${bg3x});
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80%;
  padding: 48px 32px 24px 32px;
  overflow-y: scroll;
  padding: 0 32px;
  animation: fadein 3s;
  @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }
  @media(min-width: 768px){}
  @media(min-width: 768px){
    background-size: 84%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
`;

const OptInContent = styled.section`
  @media(min-width: 768px){}
  @media(min-width: 1440px){
    background-size: 80%;
    max-width: 412px;
  }
`;

const OptInHeadline = styled.h3`
  font-family: SofiaW01;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.44;
  margin-bottom: 16px;
  margin-top: 0;
  margin-top: 10px;
  color: ${({ theme }) => theme.mi_black};
  @media(min-width: 1440px){
    margin-top: 4px;
  }
`

const OptInText = styled.p`
  font-family: SofiaW01;
  font-size: 16px;
  line-height: 1.5;
  color: ${({ theme }) => theme.mi_black};
`
const OptInButton = styled.button`
  margin-top: 16px;
  width: 144px;
  height: 40px;
  border-radius: 6px;
  box-shadow: 3px 3px 0 0 rgba(0, 0, 0, 0.12);

  font-family: SofiaW01;
  font-size: 16px;
  font-weight: bold;


  line-height: 1.5;
  letter-spacing: 1px;
  text-align: center;

  border: none;
  background-color:  ${({ theme }) => theme.mi_red};
  color: ${({ theme }) => theme.mi_white};
  @media(min-width: 768px){
    margin-top: 34px;
  }
`

const mapStateToProps = state => {
  return {
    map_opt_in_count: state.map.opt_in.count,
    map_opt_in_confirmed: state.map.opt_in.confirmed,
    map_opt_in_persisted: state.map.opt_in.persisted,
  }
};

const mapDispatchToProps = {
  optInMaps,
  setIsMapView,
  setIsCategoryView
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OptInDialogMobile);
