function initialized(state = 0, action ){
  switch( action.type ) {
  case `SET_INIATLIZED_TO_TRUE`:
    return true
    
  default:
    // console.log('SET_INIATLIZED_TO_TRUE default count')
    return state
  }
}

export default initialized;
