import React from 'react';
import styled from '@emotion/styled'
import { Link as GatsbyLink  } from 'gatsby'
import { connect } from 'react-redux';
import { setIsMapView, setIsCategoryView } from '../../../state/actions'

const HomeLink = (props) => {
  const { 
    setIsMapView,
    setIsCategoryView
  } = props;
  // console.log('HOME_LINK setIsMapView: ', setIsMapView)
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <StyledHomeLink {...props}>
      <GatsbyLink 
        className='mi_home_link'
        key="mi_header_home_link"
        to="/"
        title="Zur Startseite"
        onClick={() => {
          setIsCategoryView(false)
          setIsMapView(false)}}
      />
    </StyledHomeLink>
  )
}

const mapStateToProps = state => {
  return {
    isMapView: state.map.isMapView,
    isCategoryView: state.view.isCategoryView
  }
};

const mapDispatchToProps = {
  setIsMapView,
  setIsCategoryView
};


const StyledHomeLink = styled.div`
  width: 45px;
  margin-left: 32px;
  margin-top: 20px;

  .mi_home_link{
    display: flex;
    width: 100%;
    height: 100%;
    background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjQ2IiB2aWV3Qm94PSIwIDAgNDUgNDYiIHdpZHRoPSI0NSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Im0yMi45ODMgMzAuODIgMTAuNTE4LTI3LjAxNnYyMi44ODhoLTMuNjE0djMuODA0aDExLjUxM3YtMy44MDRoLTMuNjk2di0yMi44ODhoMy42OTZ2LTMuODA0aC0xMC41OTlsLTguMTAyIDIwLjg4NC04LjA2MS0yMC44ODRoLTEwLjQ5OHYzLjgwNGgzLjcxNnYyMi44ODhoLTMuNzE2djMuODA0aDEwLjg2M3YtMy44MDRoLTMuMzd2LTIyLjg4OGwxMC40MzYgMjcuMDE2eiIgZmlsbD0iIzAwMCIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0ibTE3LjE2MiAzNy4zNjUtMTcuMTYyIDguNDA1di0yNi4zMWwxNy4xNjItOC40MDUgMTAuMjk2IDQuMzg1IDE3LjE2Mi04Ljc3djI2LjU4N2wtMTcuMTYyIDguNDkzeiIgZmlsbD0iI2VhNTIyMyIvPjxwYXRoIGQ9Im0xNy4xNjIgMzcuMzY1di0yNi4zMWwxMC4yOTYgNC4zODV2MjYuMzF6IiBmaWxsPSIjZDE0YTIwIi8+PHBhdGggZD0ibTE3LjE2MiAzNy4zNjV2LTI2LjMxbDEwLjI5NiA0LjM4NXYyNi4zMXoiIGZpbGw9IiNkMTRhMjAiLz48cGF0aCBkPSJtMjIuOTgzIDMwLjgyIDEwLjUxOC0yNy4wMTZ2MjIuODg4aC0zLjYxNHYzLjgwNGgxMS41MTN2LTMuODA0aC0zLjY5NnYtMjIuODg4aDMuNjk2di0zLjgwNGgtMTAuNTk5bC04LjEwMiAyMC44ODQtOC4wNjEtMjAuODg0aC0xMC40OTh2My44MDRoMy43MTYgMy43NzdsMTAuNDM2IDI3LjAxNnoiIGZpbGw9IiMwMDAiIGZpbGwtcnVsZT0ibm9uemVybyIvPjwvZz48L3N2Zz4=);
    background-repeat: no-repeat;
    background-position: center top;
    pointer-events: all;
  }
  @media(min-width: 768px){
    height: 46px;
    position: absolute;
    margin-top: 27px;
    left: 50%;
    transform: translateX(-50%);
    margin-left: 0;
  }
  `

export default connect(mapStateToProps, mapDispatchToProps)(HomeLink)
