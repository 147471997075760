import React from 'react';
import styled from '@emotion/styled'
import { Link as GatsbyLink  } from 'gatsby'
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import gastronomy from "../../../images/icons/map-pin-gastronomie.svg"
import business from "../../../images/icons/map-pin-gewerbe.svg"
import arts from "../../../images/icons/map-pin-kunsthandwerk.svg"
import social from "../../../images/icons/map-pin-soziales-kulutr.svg"
import { setIsMapView, 
  setIsCategoryView, 
  setActiveParticipant, 
  activateParticipant, 
  setActivatedParticipantOffsetX,
} from '../../../state/actions'
import FluidImage from "../../FluidImage"
import GoogleMap from './GoogleMapMobile';

let LOS_ANGELES_CENTER = [34.0522, -118.2437]

const StyledMarker = styled.div`
  width: 37px;
  height: 37px;
  position: relative;
  // position: absolute;

  &.active{
    &.arts{
      filter: drop-shadow(0 0 5px rgba(0, 131, 200, 0.5));
    }
    &.social{
      filter: drop-shadow(0 0 5px rgba(87, 193, 139, 0.5));
    }
    &.business{
      filter: drop-shadow(0 0 5px rgba(254, 193, 16, 0.5));
    }
    &.gastronomy{
      filter: drop-shadow(0 0 5px rgba(234, 82, 35,0.5));
    }
    transform: scale3d(1.9, 1.9, 1.9);
  }
`
const StyledImage = styled.img`
  width: 100%;
  height: 100%;
`;

const StyledPopUp = styled(GatsbyLink)`
text-decoration: none;
@media(min-width: 768px){
  display: none;
  width: 140px;
  height: 140px;
  border-radius: 3px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;

  position: relative;
  bottom: 200px;
  left: -50px;
  flex-wrap: wrap;
  z-index: 13;
  &.active{
    display: flex;
  }
}
`

const StyledPopUpPhoto = styled.div`
width: 140px;
height: 80px;
flex: 1 0 100%;

`

const StyledPopUpTitle = styled.div`
width: 140px;
height: 60px;
font-family: SofiaW01;
font-size: 12px;
line-height: 1.4;
text-align: center;
color: var(--black);
display: flex;
align-items: center;
justify-content: center;
align-content: center;
`


const PopUp = (props) => {
  const { place, className, style } = props;
  const { title, id, shortTitle, heroImage, uri } = place;
  const displayTitle = shortTitle && shortTitle !== '' ? shortTitle : title;
  console.log('popup props: ', props)
  console.log('popup title: ', title)

  console.log('uri is: ', uri)
  return (
    <StyledPopUp to={uri} key={`info_window_${id}`} className={className} style={style}>
      <StyledPopUpPhoto>
        {heroImage && heroImage[0] ? 
          <FluidImage className="mi-card-image" image={heroImage[0]} style={{marginBottom: '0px'}} /> : null }
      </StyledPopUpPhoto>
      <StyledPopUpTitle>
        {displayTitle}
      </StyledPopUpTitle>
    </StyledPopUp>
  )
}
// InfoWindow component
// const InfoWindow = (props) => {
//   const { place } = props;
//   const infoWindowStyle = {
//     position: 'relative',
//     bottom: 150,
//     left: '-45px',
//     width: 220,
//     backgroundColor: 'white',
//     boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
//     padding: 10,
//     fontSize: 14,
//     zIndex: 100,
//   };

//   return (
//     <div style={infoWindowStyle}>
//       <div style={{ fontSize: 16 }}>
//         {place.name}
//       </div>
//       <div style={{ fontSize: 14 }}>
//         <span style={{ color: 'grey' }}>
//           {place.rating}
//           {' '}
//         </span>
//         <span style={{ color: 'orange' }}>
//           {String.fromCharCode(9733).repeat(Math.floor(place.rating))}
//         </span>
//         <span style={{ color: 'lightgrey' }}>
//           {String.fromCharCode(9733).repeat(5 - Math.floor(place.rating))}
//         </span>
//       </div>
//       <div style={{ fontSize: 14, color: 'grey' }}>
//         {place.types[0]}
//       </div>
//       <div style={{ fontSize: 14, color: 'grey' }}>
//         {'$'.repeat(place.price_level)}
//       </div>
//       <div style={{ fontSize: 14, color: 'green' }}>
//         {place.opening_hours.open_now ? 'Open' : 'Closed'}
//       </div>
//     </div>
//   );
// };

// Marker component
const Marker = ({ show, place, activated_participant_id, ...props }) => {
  const { 
    setIsMapView,
    setIsCategoryView,
    setActiveParticipant,
    activateParticipant,
    setActivatedParticipantOffsetX,
  } = props;


  const participantId = place.id;
  const id = place.categoryId
  const isActive = activated_participant_id === place.id

  // const ref = useRef(null)

  const handleLinkClick = participantId => (e, target) => {
    // console.log('\n\n\n handleLinkClick >>>>>')
    // console.log('e is: ', e);
    // console.log('target is: ', e.currentTarget.getAttribute('id'))
    // console.log('target offsetLeft is', e.currentTarget.offsetLeft)

    // NODE-SAFE CODE
    // Gatsby uses Node to generate our pages. 
    // Node doesn't know what a window is. 
    // Be sure to wrap any of your browser interactions
    // in some sort of node-safe if statement like this:
    
    if (typeof window !== 'undefined') {
      if (e) {


        e.preventDefault()
        setActiveParticipant(participantId)
        
        // setActivatedParticipantOffsetX(e.currentTarget.offsetLeft);
        activateParticipant(participantId)
        setIsCategoryView(true)
        setIsMapView(true)

      }
      // setIsCategoryView(false)
      // setIsMapView(true)
      // First, are we on the home page?
      // If so, let's scroll to the desired block,
      // which was passed in as an onClick method on our <Link />.
      // If an event was also passed, we'll preventDefault()
      
      // if (window.location.pathname === '/') {
      //   if (e) e.preventDefault()
      //   scrollToElement(target, {
      //     offset: -95, // Offset a fixed header if you please
      //     duration: 1000,
      //   })
      // }
    }
  }


  // console.log('MARKER PLACE ID: ', place.id, ' === activated_p_id -> ', activated_participant_id, ' :', isActive? 'true' : 'false');

  function Mark({ place, activated_participant_id, participantId, categoryId, markerIsActive }) {
    switch (categoryId) {
    case 9:
      return  (
        <>
          <StyledMarker onClick={handleLinkClick(participantId)} style={markerIsActive ? {zIndex: 12 } : {zIndex: 10}} className={markerIsActive ? 'active arts' : ''}>
            <StyledImage src={arts} alt="marker" />
          </StyledMarker>
          <PopUp className={markerIsActive ? 'active' : ''} place={place} {...props} style={markerIsActive ? {display: 'flex' } : {display: 'none'}} />
        </>
      )
    case 7:
      return  (
        <>
          <StyledMarker onClick={handleLinkClick(participantId)} style={markerIsActive ? {zIndex: 12 } : {zIndex: 10}} className={markerIsActive ? 'active social' : ''}>
            <StyledImage src={social} alt="marker" />
          </StyledMarker>
          <PopUp className={markerIsActive ? 'active' : ''} place={place} {...props} style={markerIsActive ? {display: 'flex' } : {display: 'none'}} />
        </>
      )
    case 5:
      return  (
        <>
          <StyledMarker onClick={handleLinkClick(participantId)} style={markerIsActive ? {zIndex: 12 } : {zIndex: 10}} className={markerIsActive ? 'active business' : ''}>
            <StyledImage src={business} alt="marker" />
          </StyledMarker>
          <PopUp className={markerIsActive ? 'active' : ''} place={place} {...props} style={markerIsActive ? {display: 'flex' } : {display: 'none'}} />
        </>
      )
    case 8:
      return  (
        <>
          <StyledMarker onClick={handleLinkClick(participantId)} style={markerIsActive ? {zIndex: 12 } : {zIndex: 10}} className={markerIsActive ? 'active gastronomy' : ''}>
            <StyledImage src={gastronomy} alt="marker" />
          </StyledMarker>
          <PopUp className={markerIsActive ? 'active' : ''} place={place} {...props} style={markerIsActive ? {display: 'flex' } : {display: 'none'}} />
        </>
      )
    default:
      return null;
    }
  }

  return ( Mark({place, activated_participant_id, participantId, categoryId: id, markerIsActive: isActive}));
};

const placesFromProps = (props) => {
  if(props.places){
    const  transformedPlaces = props.places.map(p => {
      const { latitude, longitude, streetAddress } = p.mi_participant.miParticipantGeodata;
      const categoryId = p.participantCategories.nodes[0].databaseId;

      const shortTitle = p.mi_participant.miParticipantShorttitle
      const heroImage =p.mi_participant.miParticipantHeroSlider

      return {
        title: p.title,
        shortTitle,
        heroImage,
        formatted_address : streetAddress,
        id: p.databaseId,
        lat : latitude,
        lng : longitude,
        show: false,
        categoryId,
        uri: p.uri,
      }
    })
    return transformedPlaces;
  }
  return []

}


/**
 * Get a center latitude,longitude from an array of like geopoints
 *
 * @param array data 2 dimensional array of latitudes and longitudes
 * For Example:
 * $data = array
 * (
 *   0 = > array(45.849382, 76.322333),
 *   1 = > array(45.843543, 75.324143),
 *   2 = > array(45.765744, 76.543223),
 *   3 = > array(45.784234, 74.542335)
 * );
*/
function GetCenterFromDegrees(data)
{
  // console.log('GetCenterFromDegrees ', data)
  const numCoords = data.length;
  let lat;
  let lon;
  
  if (!(data.length > 0)){
    return false;
  } 


  let X = 0.0;
  let Y = 0.0;
  let Z = 0.0;

  for(let i = 0; i < data.length; i += 1){
    lat = data[i][0] * Math.PI / 180;
    lon = data[i][1] * Math.PI / 180;

    const a = Math.cos(lat) * Math.cos(lon);
    const b = Math.cos(lat) * Math.sin(lon);
    const c = Math.sin(lat);

    X += a;
    Y += b;
    Z += c;
  }

  X /= numCoords;
  Y /= numCoords;
  Z /= numCoords;

  lon = Math.atan2(Y, X);
  const hyp = Math.sqrt(X * X + Y * Y);
  lat = Math.atan2(Z, hyp);

  const newX = (lat * 180 / Math.PI);
  const newY = (lon * 180 / Math.PI);

  return [newX, newY];
}

// export default MarkerInfoWindow;
const mapStateToPropsMarker = state => {
  // console.log('mapStateToProps FilterCategories: ', state)
  return {
    filter_category_id: state.participants.active_category.id,
    // filter_category_name: state.participants.active_category.name,
    isMapView: state.map.isMapView,
    isCategoryView: state.view.isCategoryView,
    activated_participant_id: state.participants.activated_participant_id
  }
};

const mapDispatchToPropsMarker = {
  setIsMapView,
  setIsCategoryView,
  setActiveParticipant,
  activateParticipant,
  setActivatedParticipantOffsetX,
};



const ConnectedMarker = connect(mapStateToPropsMarker, mapDispatchToPropsMarker)(Marker)

const MarkerInfoWindow = ( props ) => {
  const places = placesFromProps(props)
  const placesAsPairs = places.map(p => [p.lat, p.lng])
  LOS_ANGELES_CENTER = GetCenterFromDegrees(placesAsPairs) || [52.5292237, 13.330749]
  // console.log('CENTER IS: ', LOS_ANGELES_CENTER)

  const { activated_participant_id, filter_category_id } = props
  const centerPair = places.find(p => p.id === activated_participant_id)
  const centerLoc = centerPair? [centerPair.lat, centerPair.lng] : LOS_ANGELES_CENTER
  console.log('filter_category_id: ', filter_category_id)
  const filteredPlaces = filter_category_id? places.filter(p => p.categoryId === filter_category_id) : places;

  return (
    <>
      {/* {!isEmpty(places) && ( */}
      <GoogleMap
        center={centerLoc}
        defaultZoom={15}
        // defaultCenter={LOS_ANGELES_CENTER}
        bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_KEY }}
        // onChildClick={this.onChildClickCallback}
      >
        {filteredPlaces.map((place) => (

          <ConnectedMarker
            markerId={place.id}
            key={place.id}
            lat={place.lat}
            lng={place.lng}
            show={place.show}
            place={place}
            style={{zIndex: 300}}
            activated_participant_id={activated_participant_id}
          />
            

        ))}
      </GoogleMap>
    </>
  );
}

// InfoWindow.propTypes = {
//   place: PropTypes.shape({
//     name: PropTypes.string,
//     formatted_address: PropTypes.string,
//   }).isRequired,
// };

Marker.propTypes = {
  show: PropTypes.bool.isRequired,
  place: PropTypes.shape({
    name: PropTypes.string,
    formatted_address: PropTypes.string,
  }).isRequired,
};

// export default MarkerInfoWindow;
const mapStateToProps = state => {
  // console.log('mapStateToProps FilterCategories: ', state)
  return {
    filter_category_id: state.participants.active_category.id,
    // filter_category_name: state.participants.active_category.name,
    isMapView: state.map.isMapView,
    isCategoryView: state.view.isCategoryView,
    activated_participant_id: state.participants.activated_participant_id
  }
};

const mapDispatchToProps = {
  // filterParticipants,
  // setIsMapView,
  // setIsCategoryView
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MarkerInfoWindow);