/* eslint-disable camelcase */
const category_filter = (state = 'SHOW_MATCHED', action) => {
  switch (action.type) {
  case 'SET_CATEGORY_FILTER':
    return action.filter
  default:
    // console.log('REDUCER default category_filter')
    return state
  }
}

export default category_filter
