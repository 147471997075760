import React from "react";
import styled from '@emotion/styled'


export default function ArtsAndCrafts(props) {
  const { active } = props;
  
  return (
    <StyledSVG 
      active={active}
      xmlns="http://www.w3.org/2000/svg"
      width="60" 
      height="60" 
      viewBox="0 0 60 60"
    >
      <g fill="none" fillRule="evenodd" transform="translate(5 5)">
        <circle cx="25" cy="25" r="25" fill="#FFFFFF" className={active? 'mi-bg-active' : 'mi-bg-inactive'} />
        <g transform="translate(9 11)">
          <rect width="14" height="14" x="16" y="16" fill="#57C18B" className={active? 'mi-so-active' : 'mi-so-inactive'} />
          <polygon fill="#0083CA" points="23 0 28.473 2.636 29.824 8.558 26.037 13.307 19.963 13.307 16.176 8.558 17.527 2.636" transform="rotate(-180 23 7)" className={active? 'mi-ac-active' : 'mi-ac-inactive'} />
          <circle cx="7" cy="7" r="7" fill="#EA5223" className={active? 'mi-gs-active' : 'mi-gs-inactive'} />
          <polygon fill="#FEC110" points="7 28.31 5.442 29.824 4.696 27.784 2.636 28.473 2.849 26.311 .693 26.037 1.823 24.182 0 23 1.823 21.818 .693 19.963 2.849 19.689 2.636 17.527 4.696 18.216 5.442 16.176 7 17.69 8.558 16.176 9.304 18.216 11.364 17.527 11.151 19.689 13.307 19.963 12.177 21.818 14 23 12.177 24.182 13.307 26.037 11.151 26.311 11.364 28.473 9.304 27.784 8.558 29.824" className={active? 'mi-bn-active' : 'mi-bn-inactive'} />
        </g>
      </g>
    </StyledSVG>
  )
}

const StyledSVG = styled.svg`
  width: 100%;
  height: 100%;
  .mi-bg-active{
    fill: #ffffff;
  }
  .mi-bg-inactive{
    fill: #ffffff;
  }
  .mi-so-active{
    fill: #57C18B;
  }
  .mi-so-inactive{
    fill: #E9D8CF;
  }
  .mi-ac-active{
    fill: #0083CA;
  }
  .mi-ac-inactive{
    fill: #E9D8CF;
  }
  .mi-gs-active{
    fill: #EA5223;
  }
  .mi-gs-inactive{
    fill: #E9D8CF;
  }
  .mi-bn-active{
    fill: #FEC110;
  }
  .mi-bn-inactive{
    fill: #E9D8CF;
  }
`
/*
  The following does not work
  It seams like react does re-render the whole filterbar, 
  when the action "filterParticipants" is called.
  Trying to find a solution for an animated transition of the icons
  is to time-consuming for now


  Idea:

  Do not connect the Filterbar component to redux,
  but connect every single FilterItem, so not the whole
  component is re-rendered on a prop change
*/

/*
const StyledDiv = styled.div`
  width: 100%;
  height: 100%;

  transform: ${({ active }) => active ? 'translateX(0)' : 'translateX(50%)'};
  transition-timing-function: ${({ active }) => active ? 'cubic-bezier(0.0, 0.0, 0.2, 1)' : 'cubic-bezier(0.2, 0, 1, 0.9)'};
  transition-duration: ${({ active }) => active ? '1250ms' : '1200ms'};
  transition-property: transform;
`
*/

