import React from 'react';
import isEmpty from 'lodash.isempty';
import PropTypes from 'prop-types';

// examples:
import GoogleMap from './GoogleMap';

// consts: [34.0522, -118.2437]
// import LOS_ANGELES_CENTER from '../const/la_center';

let LOS_ANGELES_CENTER = [34.0522, -118.2437]

// InfoWindow component
const InfoWindow = (props) => {
  const { place } = props;
  const infoWindowStyle = {
    position: 'relative',
    bottom: 150,
    left: '-45px',
    width: 220,
    backgroundColor: 'white',
    boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
    padding: 10,
    fontSize: 14,
    zIndex: 100,
  };

  return (
    <div style={infoWindowStyle}>
      <div style={{ fontSize: 16 }}>
        {place.name}
      </div>
      <div style={{ fontSize: 14 }}>
        <span style={{ color: 'grey' }}>
          {place.rating}
          {' '}
        </span>
        <span style={{ color: 'orange' }}>
          {String.fromCharCode(9733).repeat(Math.floor(place.rating))}
        </span>
        <span style={{ color: 'lightgrey' }}>
          {String.fromCharCode(9733).repeat(5 - Math.floor(place.rating))}
        </span>
      </div>
      <div style={{ fontSize: 14, color: 'grey' }}>
        {place.types[0]}
      </div>
      <div style={{ fontSize: 14, color: 'grey' }}>
        {'$'.repeat(place.price_level)}
      </div>
      <div style={{ fontSize: 14, color: 'green' }}>
        {place.opening_hours.open_now ? 'Open' : 'Closed'}
      </div>
    </div>
  );
};

// Marker component
const Marker = ({ show, place }) => {
  const markerStyle = {
    border: '1px solid white',
    borderRadius: '50%',
    height: 10,
    width: 10,
    backgroundColor: show ? 'red' : 'blue',
    cursor: 'pointer',
    zIndex: 10,
  };

  return (
    <>
      <div style={markerStyle} />
      {show && <InfoWindow place={place} />}
    </>
  );
};

const placesFromProps = (props) => {
  // const example = {
  //   "databaseId": 565,
  //   "id": "cG9zdDo1NjU=",
  //   "slug": "heinrichs-gartenzubehoer",
  //   "title": "Heinrichs Gartenzubehör",
  //   "uri": "/teilnehmende/heinrichs-gartenzubehoer/",
  //   "mi_participant": {
  //     "miParticipantGeodata": {
  //       "latitude": 52.5341006,
  //       "longitude": 13.3366033,
  //       "streetName": "Siemensstraße",
  //       "streetNumber": "27",
  //       "placeId": "ChIJx_V_BXJRqEcRd-SVJcftVZs",
  //       "city": "Berlin",
  //       "postCode": "10551",
  //       "streetAddress": "Moabiter Stadtgarten, Siemensstraße, Berlin, Germany"
  //     }
  //   }
  // }
  if(props.places){
    const  transformedPlaces = props.places.map(p => {
      const { latitude, longitude, streetAddress } = p.mi_participant.miParticipantGeodata;
      return {
        formatted_address : streetAddress,
        id: p.databaseId,
        lat : latitude,
        lng : longitude,
        show: false
      }
    })
    return transformedPlaces;
  }
  return []

}


/**
 * Get a center latitude,longitude from an array of like geopoints
 *
 * @param array data 2 dimensional array of latitudes and longitudes
 * For Example:
 * $data = array
 * (
 *   0 = > array(45.849382, 76.322333),
 *   1 = > array(45.843543, 75.324143),
 *   2 = > array(45.765744, 76.543223),
 *   3 = > array(45.784234, 74.542335)
 * );
*/
function GetCenterFromDegrees(data)
{       
  const numCoords = data.length;
  let lat;
  let lon;
  
  if (!(data.length > 0)){
    return false;
  } 


  let X = 0.0;
  let Y = 0.0;
  let Z = 0.0;

  for(let i = 0; i < data.length; i += 1){
    lat = data[i][0] * Math.PI / 180;
    lon = data[i][1] * Math.PI / 180;

    const a = Math.cos(lat) * Math.cos(lon);
    const b = Math.cos(lat) * Math.sin(lon);
    const c = Math.sin(lat);

    X += a;
    Y += b;
    Z += c;
  }

  X /= numCoords;
  Y /= numCoords;
  Z /= numCoords;

  lon = Math.atan2(Y, X);
  const hyp = Math.sqrt(X * X + Y * Y);
  lat = Math.atan2(Z, hyp);

  const newX = (lat * 180 / Math.PI);
  const newY = (lon * 180 / Math.PI);

  return [newX, newY];
}


const MarkerInfoWindow = ( props ) => {
  const places = placesFromProps(props)
  const placesAsPairs = places.map(p => [p.lat, p.lng])
  LOS_ANGELES_CENTER = GetCenterFromDegrees(placesAsPairs) || [52.5292237, 13.330749]
  return (
    <>
      {/* {!isEmpty(places) && ( */}
      <GoogleMap
        defaultZoom={13}
        // defaultCenter={LOS_ANGELES_CENTER}
        bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_KEY }}
        // onChildClick={this.onChildClickCallback}
      >
        {places.map((place) => (
          <Marker
            key={place.id}
            lat={place.lat}
            lng={place.lng}
            show={place.show}
            place={place}
          />
        ))}
      </GoogleMap>
      {/* )} */}
    </>
  );
}


// class MarkerInfoWindow extends Component {
//   constructor(props) {
//     console.log('MarkerInfoWindow props: ', props)
//     super(props);
//     const places = placesFromProps(props)

//     const placesAsPairs = places.map(p => [p.lat, p.lng])

//     LOS_ANGELES_CENTER = GetCenterFromDegrees(placesAsPairs)
//     console.log('LOS_ANGELES_CENTER: ', LOS_ANGELES_CENTER)
//     this.state = {
//       places
//     };
//   }

// componentDidMount() {

//   // fetch('/places.json')
//   //   .then((response) => response.json())
//   //   .then((data) => {
//   //     data.results.forEach((result) => {
//   //       result.show = false; // eslint-disable-line no-param-reassign
//   //     });
//   //     this.setState({ places: data.results });
//   //   });
// }

// onChildClick callback can take two arguments: key and childProps
// onChildClickCallback = (key) => {
//   this.setState((state) => {
//     const index = state.places.findIndex((e) => e.id === key);
//     state.places[index].show = !state.places[index].show; // eslint-disable-line no-param-reassign
//     return { places: state.places };
//   });
// };

//   render() {
//     const { places } = this.state;
//     console.log('render places : ', places )

//     return (
//       <>
//         {!isEmpty(places) && (
//           <GoogleMap
//             defaultZoom={10}
//             defaultCenter={LOS_ANGELES_CENTER}
//             bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_KEY }}
//             // onChildClick={this.onChildClickCallback}
//           >
//             {places.map((place) => (
//               <Marker
//                 key={place.id}
//                 lat={place.lat}
//                 lng={place.lng}
//                 show={place.show}
//                 place={place}
//               />
//             ))}
//           </GoogleMap>
//         )}
//       </>
//     );
//   }
// }

InfoWindow.propTypes = {
  place: PropTypes.shape({
    name: PropTypes.string,
    formatted_address: PropTypes.string,
    // rating: PropTypes.number,
    // types: PropTypes.arrayOf(PropTypes.string),
    // price_level: PropTypes.number,
    // opening_hours: PropTypes.shape({
    //   open_now: PropTypes.bool,
    // }),
  }).isRequired,
};

Marker.propTypes = {
  show: PropTypes.bool.isRequired,
  place: PropTypes.shape({
    name: PropTypes.string,
    formatted_address: PropTypes.string,
    // rating: PropTypes.number,
    // types: PropTypes.arrayOf(PropTypes.string),
    // price_level: PropTypes.number,
    // opening_hours: PropTypes.shape({
    // open_now: PropTypes.bool,
    // }),
  }).isRequired,
};

export default MarkerInfoWindow;
