

const defaults = {
  href: null,
  action: null,
}

function route(state = defaults, action ) {
  switch(action.type){
  case 'UPDATE_ROUTE':
    // action.asyncDispatch({ type: "fetch-response", value: r }))
    return { ...state, href : action.href };

  default:
    // console.log('REDUCER default view')
    return state;
  }
}

export default route;
