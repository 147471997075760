import React from 'react';
import styled from '@emotion/styled'
import GoogleMapReact from 'google-map-react';
import PropTypes from 'prop-types';
import mapStyles from '../gmaps-styles-moabiterinsel.json';

const Wrapper = styled.main`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 5;
  overflow: hidden;
`;

const mapOptions = {
  styles: mapStyles, // straight out of something like snazzymaps
  controlSize: 30,
  disableDefaultUI: true,
  zoomControl: true,
  gestureHandling: "greedy",
  draggable: true,
  minZoom: 14,

};

const GoogleMapMobile = ({ children, ...props }) => {
  const { center } = props;

  return (
    <Wrapper>
      <GoogleMapReact
        center={center}
        options={mapOptions}
        bootstrapURLKeys={{
          key: process.env.REACT_APP_MAP_KEY,
        }}
        {...props}
      >
        {children}
      </GoogleMapReact>
    </Wrapper>
  )};

GoogleMapMobile.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

GoogleMapMobile.defaultProps = {
  children: null,
};

export default GoogleMapMobile;
