function count(state = 0, action ){
  switch( action.type ) {
  case `INCREMENT`:
    return state + 1
  default:
    // console.log('REDUCER default count')
    return state
  }
}

export default count;
